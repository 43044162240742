import React from 'react';

const Link = ({ data }) => {
	return (
		<a aria-label={ data.id }  className='social-media-link' href={ data.linkUrl } title={ data.linkFor } rel="noopener noreferrer" target="_blank">
			<i className={ `fa ${data.icon}` } />
		</a>
	);
};

export default function SocialLinks({ links }) {
	return (
		<div className="social-media-details">
			{
				links.map(link => {
					return <Link key={ link.id } href={ `${link}` } data={ link } />
				})
			}
		</div>
	);
}