/* eslint-disable no-console */
import React from 'react';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import io from 'socket.io-client';
import moment from 'moment';
import momenttz from 'moment-timezone';
import get from 'lodash.get';
import { withRouter } from 'react-router-dom'
import { SET_ACTION_SUCCESS_MESSAGE } from '../redux/types';
import { getParams, getRole, getTabId, saveException } from '../util';
import router from '../util/router';
import SessionStorageServices from '../util/sessionStorageServices';
import getHost from '../util/host';

class IdentityCheck extends React.PureComponent {
  constructor(props) {
    super(props);
    this.uid = '';
    this.userAgent = '';
    this.identityCheckInitiated = false;
    this._connectSocket = this._connectSocket.bind(this);
  }

  async componentDidMount() {
    const { uid, sessionId } = this._getIds();
    if (!this.identityCheckInitiated && (uid || sessionId)) {
      this.uid = uid || sessionId;
      if (this._shouldStartSocketConnection()) {
        this._connectSocket.call(this);
        this.identityCheckInitiated = true;
        console.log(
          'IDENTITY CHECK:: Inititating Identity Check in ComponentDidMount',
        );
        console.log('IDENTITY CHECK:: UID: ', this.uid);
      }
    }
  }

  componentDidUpdate() {
    const { uid, sessionId } = this._getIds();
    const { userAgent } = this.props;
    this.userAgent = userAgent;

    if (!this.identityCheckInitiated && (uid || sessionId)) {
      this.uid = uid || sessionId;
      if (this._shouldStartSocketConnection()) {
        this._connectSocket.call(this);
        this.identityCheckInitiated = true;
        console.log(
          'IDENTITY CHECK:: Inititating Identity Check in componentDidUpdate',
        );
        console.log('IDENTITY CHECK:: UID: ', this.uid);
      }
    }
  }

  _getIds = () => {
    const { search } = window.location;
    let uid = '';
      let sessionId = '';
    if (search.match(/uid/)) {
      uid = getParams('uid');
    }
    if (search.match(/session_id/)) {
      sessionId = getParams('session_id');
    }
    return {
      uid,
      sessionId,
    };
  };

  _getUserData = () => {
    return {
      userAgent: navigator.userAgent,
      sourcePage: window.location.pathname,
      sourceUrl: window.location.search,
      userTimeZone: moment(),
      currentDateTime: momenttz.tz.guess(),
    };
  };

  _shouldStartSocketConnection() {
    const { hasIdentityCheckEnabled } = this.props;

    // Checks if the identity is valid or not based on uid.
    const identity = SessionStorageServices.getIdentity(getParams('uid'));
    if (identity === 'invalid') {
      return false;
    }
    return hasIdentityCheckEnabled;
  }

  _connectSocket() {
    const { WS_BASE_URL } = getHost();

    // initiate socket connection
    this.socket = io.connect(WS_BASE_URL, {
      transports: ['websocket'],
      upgrade: false,
      query: {
        ...this._getUserData(),
        uid: this.uid,
        role: getRole(),
        tabId: getTabId(),
        feature: 'identity',
      },
      reconnection: true,
			reconnectionAttempts: process.env.REACT_APP_SOCKET_RECONNECTION_ATTEMPT || 50,
			reconnectionDelay: 1000,
      reconnectionDelayMax: 2000,
    });
    console.log(this.socket);

    this.socket.emit('START_IDENTITY_CHECK', {
      ...this._getUserData(),
      uid: this.uid,
    });

    // Listen socket reconnection
    this.socket.on('reconnect', () => {
      const uid = getParams('uid');
      if (uid) {
        console.log('IDENTITY CHECK::: Reconnecting : ', WS_BASE_URL);
      }
      this.socket.emit('START_IDENTITY_CHECK', {
        ...this._getUserData(),
        uid: this.uid,
        reconnect: true,
      });
    });

    this.socket.on('reconnect_error', (error) => {
      this.props.logError(`socket reconnect_error for ${this.uid} due to`, error)
    })

    this.socket.on('reconnect_failed', (error) => {
      this.props.logError(`socket reconnect_failed for ${this.uid} due to`, error)
    })
    

    this.socket.on('error', (error) => {
      this.props.logError(`socket connection error for ${this.uid} due to `, error)
    })

    // listen to inactive session
    this.socket.on('PING_INACTIVE_SESSION', (data) => {
      console.log(
        'IDENTITY CHECK::: SOCKET Data received',
        JSON.stringify(data),
      );
      const uid = getParams('uid');
      const sessionId = getParams('session_id');
      const isDocumentRoute =
        window.location.pathname.indexOf('/view-document') > -1;

      if (uid === data.uid || sessionId === data.uid || isDocumentRoute) {
        const currentStatus = get(
          data,
          'res.response.other_params.current_status',
          '',
        );
        const { pathname } = window.location;
        if (pathname && currentStatus) {
          this.socket.emit('PONG_INACTIVE_SESSION', {
            uid: this.uid,
            ...this._getUserData(),
          });
          const { handleInactiveSessionResponse } = this.props;
          handleInactiveSessionResponse({
            data: data.res,
          });
        }
        SessionStorageServices.setIdentity(uid, 'invalid');
      }
    });
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

const mapStateToProps = (state, props) => {
  const userAgent = get(state, 'meta.userAgent', '');
  const hasIdentityCheckEnabled = get(
    props,
    'theme.config.features.identityCheck.enable',
    false,
  );
  // const errorBoundryMessage = get(state, 'questions.errorBoundryMessage', '');
  return {
    uid: state.uid,
    userAgent: `${userAgent.name} v${userAgent.version}`,
    hasIdentityCheckEnabled,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleInactiveSessionResponse: (response) => {
      const uid = get(response, 'data.response.other_params.uid', '');
      const currentStatus = get(
        response,
        'data.response.other_params.current_status',
        '',
      ).toUpperCase();
      router(dispatch, uid, false, response);
      dispatch({ type: SET_ACTION_SUCCESS_MESSAGE });
      dispatch({
        type: `GET_${currentStatus}_SUCCESS`,
        payload: get(response, 'data.response', null),
        params: {},
      });
    },
    logError: (error) => {
      saveException({ message: error, apiName: 'socket.io' })
    }
  };
};

export default withTheme(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(IdentityCheck)),
);
