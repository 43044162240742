import get from 'lodash.get';
import { push } from 'connected-react-router';
import { getCurrentBreadcrumb } from '../redux/reducers/helper';
import { PAGE_ACTIVITY_LOGGER } from '../redux/types';

export default function router(dispatch, uid, isReflexive, response) {
	const currentStatus = get(response, 'data.response.other_params.current_status', '');
	const breadcrumbs = get(response, 'data.response.breadcrumbs', []);
	const currentBreadcrumb = getCurrentBreadcrumb(breadcrumbs);
	const pageActivityLogger =['completed','signature'];
	const role = get(response, 'data.response.other_params.role', '');
  
  if(pageActivityLogger.indexOf(currentStatus)>-1){
    dispatch({
      type:PAGE_ACTIVITY_LOGGER,
      params:{ currentStatus },
    })
		const redirectUrl = get(response, 'data.response.other_params.next_button_redirect_url', false);
		if(redirectUrl) {
			window.location.replace(redirectUrl);
		}
  }

	const isCsr = role && role.toUpperCase() === 'CSR' ? '&role=csr' : '';
	const utmQueryString = window.sessionStorage.getItem('utm_query_string');
	const utmTagsQueryString = utmQueryString && currentStatus === 'quote' ? `&${utmQueryString}` : '';

	if (currentStatus === 'questions') {
		dispatch(push(`/questions/${currentBreadcrumb.id}?uid=${uid}${isCsr}`));
	} else if (uid) {
		dispatch(push(`/${currentStatus}?uid=${uid}${isCsr}${utmTagsQueryString}`));
	} else {
		dispatch(push(`/${currentStatus}`));
	}
}
