/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
// import 'font-awesome/css/font-awesome.min.css';
import styled from 'styled-components';
import './footer.css';
import SocialLinks from './SocialLinks';
import FooterLinks from './FooterLinks';
import FooterBottomLinks from './FooterBottomLinks';
import NeedHelpButton from './NeedHelpButton';
import ProcessFooterString from '../../config/html/ProcessFooterString';

const FooterRoot = styled.div`
  background-color: ${(props) =>
    props.backgroundColor || 'rgba(59, 83, 105, 1)'};
  color: ${(props) => props.color};
  padding-top: 20px;
  padding-bottom: 20px;
  font-family: ${(props) => props.fontFamily || 'inherit'};
  font-display: swap;
`;

const Img = styled.img`
  height: auto;
  width: auto;
  max-width: 150px;
`;

const Bold = styled.p`
  font-weight: bold;
`;

const GlobalFooter = styled.div`
  padding: 50px 0;
  background: ${({ background }) => background};
  @media (forced-colors: active) {
    border-top: 1px solid WindowText;
  }
`;

const Partners = styled.div`
  margin: 20px 0;
`;

const ContainerStylesHtml = styled.div`
  display: flex;
  align-items: center;
`

function Footer({ footer, isRequestHelpButton, uid }) {
  const {
    color,
    background,
    link,
    altText,
    logo,
    phoneText,
    phoneNumber,
    footerLinksTop,
    socialLinks,
    disclaimerDesc,
    footerLinksBottom,
    isHTML,
    btnTheme,
  } = footer;

  return (
      <FooterRoot
        color={ color }
        name="FooterRoot"
        backgroundColor={ background }
        fontFamily={ footer.fontFamily }
      >
        <GlobalFooter background={ background } className="global-footer">
          {isHTML ? (
            <ContainerStylesHtml>
              <ProcessFooterString/>
              {isRequestHelpButton && 
                <NeedHelpButton 
                  buttonText = "Request Help"
                  uid = { uid }
                  btnTheme = { btnTheme }
                />
              }
              
            </ContainerStylesHtml>
          ) : (
            <div className="container">
              <div className="header-information-footer">
                <div className="company-logo">
                  <a href={ link } target="_blank" rel="noopener noreferrer">
                    <Img src={ logo } alt={ altText } />
                  </a>
                </div>
                <div className="contact-number">
                  <div>
                    <strong>{`${phoneText} `}</strong>
                    {phoneNumber ? (
                      <a href={ `tel:${phoneNumber}` }>{`(${phoneNumber})`}</a>
                    ) : null}
                  </div>
                </div>
              </div>
              <SocialLinks links={ socialLinks } />
              <div className="footer-text-container">
                <FooterLinks links={ footerLinksTop } />
                {footer.businessPartners && (
                  <Partners>
                    <span className="disclaimer-text">
                      {footer.businessPartners}
                    </span>
                    <FooterLinks links={ footer.footerBusinessLinks } />
                  </Partners>
                )}
                <p className="disclaimer-text">{disclaimerDesc}</p>
                {footer.footerTextBottom && (
                  <Bold>{footer.footerTextBottom}</Bold>
                )}
                <FooterBottomLinks links={ footerLinksBottom } />
              </div>
            </div>
          )}
        </GlobalFooter>
      </FooterRoot>
  );
}

Footer.propTypes = {
  footer: PropTypes.object.isRequired,
};

export default Footer;
