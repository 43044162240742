const SessionStorageServices = (() => {
    const storage = window.sessionStorage;
    const identityObjName = `${process.env.REACT_APP_CARRIER_ID}.${window.location.host}.identity`;

    const _getIdentity = (uid) => {
        return storage.getItem(`${identityObjName}.${uid}`);
    }

    const _setIdentity = (uid, identity) => {
        storage.setItem(`${identityObjName}.${uid}`, identity);
    };

    const _clearIdentity = (uid) => {
        storage.removeItem(`${identityObjName}.${uid}`);
    }

    return {
        getIdentity: _getIdentity,
        setIdentity: _setIdentity,
        clearIdentity: _clearIdentity,
    };
})();

export default SessionStorageServices;
