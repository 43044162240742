/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
import React from 'react';
import debounce from 'lodash.debounce';
import get from 'lodash.get'
import ReactHtmlParser from 'html-react-parser';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { DESKTOP, MOBILE, setDeviceType, setSmallScreen, TABLET } from './redux/actions/meta';

/**
 * @description checks the innerHTML string for script tag.
 * @param str
 * @returns {string}
 */
const validateInnerHtml = (str) => {
  if (!str) return '';
  if (typeof str !== 'string') return str;
  try {
    if (str.match(/<script>/ig)) {
      throw new Error('Cannot insert script tags')
    }
    return str;
  } catch (e) {
    console.error(e);
    return '';
  }
}

const stripHtml = (html) => {
  const tmp = document.createElement('DIV')
  tmp.innerHTML = html
  return tmp.textContent || tmp.innerText || ''
}

const parseHtml = (htmlText, clickHandler) => {
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {  
      clickHandler(e);
    }
  }
  return ReactHtmlParser(htmlText, {
    transform: (node) => {
      if(node.name === 'a' && node.attribs && node.attribs.modal_reference_id) {
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      return (<a onClick={ clickHandler } className='anchor' onKeyDown={ (e) => handleKeyDown(e) } role='button' tabIndex='0'>{node.children[0].data}</a>);
      }
    },
  })
}

const extractInnerHtml = (htmlString) => {
  // eslint-disable-next-line no-undef
  return new DOMParser().parseFromString(htmlString, 'text/html').documentElement.textContent;
};

/**
 * @description builds the nested array based on reflexive_index.
 * e.g. [q0, q0, [q1, q1, [q2, q2]], q0, q0]
 * @param list flattened list of questions
 * @param index
 * @returns {*}
 */
function rebuildQuestionList (list, index, doneIndex = -1) {
  return list.reduce((acc, q, i) => {
    if (q.reflexive_index === undefined) { q.reflexive_index = 0; }
    if (i >= doneIndex && !q.used && q.reflexive_index === index) {
      acc.push(q)
      q.used = true
    }
    if (!q.used && q.reflexive_index > index && doneIndex !== list.length) {
      acc.push(rebuildQuestionList(list, q.reflexive_index, i))
    }
    if(i > doneIndex && q.reflexive_index < index) {
      doneIndex = list.length
    }
    return acc;
  }, []);
}

function getRandomId() {
  return Math.random().toString(36).substring(7);
}
function getDeviceType(width){
  if(width>1024){
    return DESKTOP
  }
  if(width>767){
    return TABLET
  }
  return MOBILE
}

function getRoleAndPageType() {
  const url = new URL(document.URL);
  return {
    pageType: url.pathname
      .split('/')
      .filter(path => !!path)
      .shift(),
    role: url.searchParams.get('role'),
  };
}

function resizeHandler(dispatch, getState) {
  const { meta } = getState();
  const { isSmallScreen, deviceType } = meta;
  const { role, pageType } = getRoleAndPageType();
  if (!role && pageType === 'questions' && window.innerWidth < 900) {
    // DTC Part A page
    dispatch(setSmallScreen(true));
  } else if (window.innerWidth < 768 && !isSmallScreen) {
    dispatch(setSmallScreen(true));
  } else if (isSmallScreen && window.innerWidth > 767) {
    dispatch(setSmallScreen(false));
  }
  const currentDeviceType = getDeviceType(window.innerWidth);
  if (deviceType !== currentDeviceType) {
    dispatch(setDeviceType(currentDeviceType));
  }
}

const _resizeHandler = debounce(resizeHandler, 500);

function resizeListener({ dispatch, getState }) {
  _resizeHandler(dispatch, getState);
}

function singleButtonPage (id) {
  return id === 'quote' || id === 'quotereview' || id === 'review' || id === 'login';
}

function isExitPage (id) {
  return ['knockout', 'thankyou', 'completed', 'noquote', 'nonclean'].includes(id);
}

function isSignaturePage (id) {
  return id === 'signature';
}

function isReview (id) {
  return id === 'quotereview' || id === 'review';
}

const buttonLabelMap = {
  login: 'Login',
  signature: 'Begin E-sign',
  quote: 'Get your quote',
  quotereview: 'Continue to application',
  review: 'Continue to application',
}

function isIe() {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE');

  // If Internet Explorer, return version number
  if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./)) {
    return true;
  }
  return false;
}

function sanitize$uriefyQuestion (questionId) {
  try {
    return questionId.split(/_\$ureify_/)[1]
  } catch(e) {
    console.error('Failed to sanitize question id');
    return null;
  }
}

function isMatch(question, id) {
  if (question &&
    (question.question_id.indexOf(id) > -1 ||
    id.indexOf(question.question_id) > -1))
  {
    return true;
  }
  return false;
}

function lookUp(questionsArr, id) {
  return questionsArr.reduce((acc, q) => {
    if (acc) return acc;
    if (isMatch(q, id)) return q;
    if (q.questions) return lookUp(q.questions, id);
    return null;
  }, null);
}

function questionLookup(questionObj, id) {
  return lookUp([questionObj], id);
}

// Utility to flatten out nested questions
const flattenQuestions = (questions, newList) => {
  if (questions && questions.length) {
    questions.forEach((question) => {
      if (!question.is_hidden) {
        newList.push({
          ...question,
        });
      }
      if (question.questions && question.submission_type !== 'all') {
        flattenQuestions(question.questions, newList);
      }
    });
  }
}

const insertReflexiveQuestions = (item) => {
  const reflexiveQ = [];
  flattenQuestions(item, reflexiveQ);
  return reflexiveQ;
}

const getPopupDetails = (question = {}, popupQuestions = []) => {
  const {
    question_text: questionText,
    properties,
  } = question;

  const modalRefIdRegex = /modal_reference_id='([^']+)'/ig;  
  let modalReferenceId;

  if (questionText.match(modalRefIdRegex)) {
    modalReferenceId = questionText.match(modalRefIdRegex)[0].split('=')[1].replace(/['"]+/g, '');
  } else {
    modalReferenceId = get(properties, 'modal_reference_id', null)
  }

  if (!modalReferenceId) {
    return { show: 'false', question: '' }
  }

  let popupQuestion;
  if (popupQuestions && popupQuestions.length) {
    [popupQuestion] = popupQuestions.filter(popQ => popQ.question_id === modalReferenceId);
  }

  if (popupQuestion) {
    return { show: true, question: popupQuestion }
  }

  return { show: 'false', question: '' }
}

// Utility for scrolling to top of the screen.
const scrollTop = () =>{
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

/**
 * @description scrolls question into viewport. 
 * eg: when question is beneath fixed header,
 *     need to scroll the question into visible viewport when focused
 * @param {HTMLElement} element html element to determine its position
 * @param {object} theme theme object to determine headerPosition
 * @param {string} pageId curent page id
 * @param {string} role user role type
 * @param {string} heightFromTop optional scroll height
 */
const scrollQuestionIntoView = ({ element, theme, pageId, role, heightFromTop = 350, deviceType }) => {
  const { headerPosition } =  get(theme, `config.theme.pageOverrides.${pageId}.globalHeaderTheme`, {});
  const headerHeight = deviceType === 'MOBILE' ? 150 : 200;
  if (headerPosition && headerPosition === 'fixed' && role !== 'csr') {
    if (element && typeof element.getBoundingClientRect === 'function') {
      const { top, bottom } = element.getBoundingClientRect() || {};
      if ((top || top === 0) && top < headerHeight) {
        if (top < 0) {
          element.scrollIntoView();
        }
        window.scrollBy({ top: -(heightFromTop - top) , behavior: 'smooth' })
      }
      if (deviceType === 'MOBILE' && bottom > (window.screen.height - 150)) {
        window.scrollBy({ top: 200 , behavior: 'smooth' })
      }
    };
  }
}

// retrieve the refresh_token from cookies incase of 3rd party verification
const getCookie = (name) => {
  const cookie = {};
  document.cookie.split(';').forEach(el => {
    const [k,v] = el.split('=');
    cookie[k.trim()] = v;
  })
  return cookie[name];
}
const formotivConfig = window?.formotiv ? window.formotiv.FormotivDefaultConfig.Instance(process.env.REACT_APP_FORMOTIV_USER_ID) : null;

const setFormotivPtv = (index = 1, value) => {
  if(formotivConfig) {
    formotivConfig.state[`ptv${index}`] = value;
  }
};

const getFormotivAltUriFromTitle = () => document.title.split('|')[0].replace('Amica', '').trim();

const updateFormotivConfig = (configType = '', value, producerCode, partyId) => {
  if(!formotivConfig) return false;
  if (configType === 'altFormURI') {
    value = !value ? getFormotivAltUriFromTitle() : value;
    // If there is screen change detected, update altFormURI and init again
    if (formotivConfig.discovery.altURIHandler.altFormURI !== value) {
      formotivConfig.discovery.altURIHandler.altFormURI = value;
      return true;
    }
    return false;
  } if (configType === 'agentsettings') {
    // Only update agent setting when role change detected
    if (formotivConfig.features.agentsettings.enabled !== value) {
      formotivConfig.features.agentsettings.enabled = value;
      formotivConfig.features.agentsettings.agentId = producerCode;
      formotivConfig.features.agentsettings.appId = partyId;
    }
  }
  return false;
}

const doFormotivAction = (action = '', isFinalScreen = false, inputObj = { name: '', type: '', value: '' }) => {
  if(formotivConfig) {
    if(action === 'submit') {
      window.formotiv.submit(formotivConfig, isFinalScreen);
    } else if(action === 'submitFinal') {
      window.formotiv.submitFinal(formotivConfig);
    } else if(action === 'init') {
      window.formotiv.init(formotivConfig);
    } else if (action === 'inputActivity') {
      window.formotiv.inputActivity(formotivConfig, inputObj.name, inputObj.type, inputObj.value);
    }
  }
}

function getOperatingSystem(userAgent) {
  let operatingSystem = 'Not known';
  if (userAgent.indexOf('Win') !== -1) { operatingSystem = 'Windows OS'; }
  if (userAgent.indexOf('Mac') !== -1) { operatingSystem = 'MacOS'; }
  if (userAgent.indexOf('X11') !== -1) { operatingSystem = 'UNIX OS'; }
  if (userAgent.indexOf('Linux') !== -1) { operatingSystem = 'Linux OS'; }

  return operatingSystem;
}

const getUTCTimeStamp = () => {
  const dateIsoString = new Date(new Date().toUTCString()).toISOString();
  return `${dateIsoString.split('.')[0].replace('T', ' ')  } UTC`;
}


function isObject(value) {
  const type = typeof value
  return value != null && (type === 'object' || type === 'function')
}

function isObjectLike(value) {
  return typeof value === 'object' && value !== null
}

/**
 * Gets the `toStringTag` of `value`.
 *
 * @private
 * @param {*} value The value to query.
 * @returns {string} Returns the `toStringTag`.
 */

const { toString } = Object.prototype;

function getTag(value) {
  if (value == null) {
    return value === undefined ? '[object Undefined]' : '[object Null]'
  }
  return toString.call(value)
}

function isPlainObject(value) {
  if (!isObjectLike(value) || getTag(value) !== '[object Object]') {
    return false
  }
  if (Object.getPrototypeOf(value) === null) {
    return true
  }
  let proto = value
  while (Object.getPrototypeOf(proto) !== null) {
    proto = Object.getPrototypeOf(proto)
  }
  return Object.getPrototypeOf(value) === proto
}

function last(array) {
  const length = array == null ? 0 : array.length
  return length ? array[length - 1] : undefined
}

// function withRouter(Component) {
//   function ComponentWithRouterProp(props) {
//       const location = useLocation();
//       const navigate = useNavigate();
//       const params = useParams();

//       return <Component { ...props } router={ { location, navigate, params } } />;
//   }

//   return ComponentWithRouterProp;
// }

export {
  validateInnerHtml,
  rebuildQuestionList,
  getRandomId,
  getRoleAndPageType,
  resizeListener,
  isReview,
  singleButtonPage,
  isExitPage,
  isSignaturePage,
  buttonLabelMap,
  isIe,
  sanitize$uriefyQuestion,
  questionLookup,
  insertReflexiveQuestions,
  getPopupDetails,
  scrollTop,
  scrollQuestionIntoView,
  getDeviceType,
  getCookie,
  stripHtml,
  parseHtml,
  extractInnerHtml,
  getFormotivAltUriFromTitle,
  setFormotivPtv,
  doFormotivAction,
  updateFormotivConfig,
  getOperatingSystem,
  getUTCTimeStamp,
  isPlainObject,
  last,
  isObject,
  // withRouter,
}
