/* eslint-disable no-alert */
/* eslint-disable no-case-declarations */
/* eslint-disable no-restricted-syntax */
// import postQuestion from "../actions/questionAction";
import get from 'lodash.get';
import { getUTCTimeStamp, last } from '../../utils';
import {
	GET_NEW_QUESTION,
	API_IN_PROGRESS,
	UPDATE_QUESTION_ANSWER,
	GET_NEW_QUESTION_START,
	GET_QUESTIONS_SUCCESS,
	SET_CURRENT_BREADCRUMB,
	GET_REVIEW_SUCCESS,
	GET_QUOTE_SUCCESS,
	GET_QUESTION_FAILURE,
	GET_DOCUMENT_SUCCESS,
	GET_PLAN_SUCCESS,
	GET_QUOTEREVIEW_SUCCESS,
	GET_SIGNATURE_SUCCESS,
	GET_PAYMENT_SUCCESS,
	SET_ACTION_SUCCESS_MESSAGE,
	SET_ACTION_FAILURE_MESSAGE,
	GET_TIMEOUT_SUCCESS,
	GET_CHALLENGE_SUCCESS,
	GET_RIDERS_SUCCESS,
	GET_COMPLETED_SUCCESS,
	GET_LOGIN_SUCCESS,
	GET_KNOCKOUT_SUCCESS,
	GET_INACTIVESESSION_SUCCESS,
	GET_NOQUOTE_SUCCESS,
	GET_NONCLEAN_SUCCESS,
	UPDATE_DATA_SELECTOR_OPTIONS,
	TOGGLE_ESIGN_BUTTON,
	UPDATE_LIST_SECTION,
	GET_UNAUTHORIZE_SUCCESS,
	UPDATE_LAST_FOCUSED_ELEMENT_ID,
	UPDATE_QUESTION_ANSWER_TIME_STAMP,
	SET_POPUP_DETAILS,
	GET_NOTFOUND_SUCCESS,
} from '../types';
import HELPER, { getCurrentBreadcrumb } from './helper';
import { 
	isListQuestion, 
	getListDisplayMode, 
	hasListItems, 
	getAddButtonText, 
	getQuestionStatus,
} from '../actions/listQuestionHelper';

const initialState = {
	breadcrumbs: {
		currentBreadcrumb: {},
		isBreadcrumbLoaded: true,
		breadcrumbList: [],
	},
	isLoading: true,
	currentPageSeqNumber: 0,
	sectionIndex: 0,
	isListAddAction: false,
	activeSubsectionId: '',
	lastFocusedElementId: '',
	actionMessage: {
		type: '',
		message: '',
	},
	eSign: {
		hasESign: false,
		eSignEnable: false,
	},
	lastInteractedQuestion: {},
	lastSectionSubmittedAt: '',
	sections: {
		'testSections': [
			{
				id: '12345',
				answersArray: [],
				errorsArray: [],
				answeredListArray:[],
				otherParams: {},
				errors: {},
				pageDesc: {},
				pageSeqNumber: 0,
				questionList: {},
				leftQuestionList: [],
				layoutRatio: {},
				requiredSchema: {},
				enableContinue: false,
				list: {
					isList: false,
					listDisplay: false,
					hasItems: false,
					addButtonText: 'Add',
					originalQuestions: [],
					questionObj: {},
				},
				listIndex: -1,
				listEditIndex: -1,
				listEditQId: '',
				questionObj: {},
			},
		],
	},
};

function updateBreadcrumbs(breadcrumbs = {}, payload) {
	const breadcrumbList = payload.reduce((acc, breadcrumb) => {
		acc.push({
			...breadcrumb,
			id:
				breadcrumb.breadcrumb_id.toLowerCase() ||
				breadcrumb.title.replace(/ /g, '').toLowerCase(),
		});
		return acc;
	}, []);
	return {
		...breadcrumbs,
		breadcrumbList,
		currentBreadcrumb: getCurrentBreadcrumb(breadcrumbList),
		isBreadcrumbLoaded: true,
	}
}

function getLeftAndRightData(questionItem, skip) {
	const layout = get(questionItem, 'properties.layout', undefined);
	let leftQuestionList;
	let rightQuestionList;
	if(!skip && layout && layout.value === 'two_columns') {
	const allQuestions = get(questionItem, 'questions', []);
		for (let i=0; i< allQuestions.length; i+=1) {
			const question = allQuestions[i];
			const displayPosition = get(question, 'properties.display_position.value', '');
			if(displayPosition === 'left') {
				leftQuestionList = HELPER.insertReflexiveQuestion(question)
			} else if(displayPosition === 'right') {
				rightQuestionList = HELPER.insertReflexiveQuestion(question);
			}
		}
		return {
			layoutRatio: layout.size,
			leftQuestionList,
			rightQuestionList,
		};
	}
	rightQuestionList = HELPER.insertReflexiveQuestion(questionItem);
	return { layoutRatio: {}, leftQuestionList: [], rightQuestionList }
}

const getFormattedReviewResponse = (questionObj) => {
	const questionsList = [];
	if (questionObj && questionObj.length) {
		questionObj.forEach(ques => {
			if (ques.display_type === 'questions_group') {
				const breadcrumbQuestions = ques.questions;
				// const breadcrumbGroup = { ...ques, questions: [] };
				// questionsList.push(breadcrumbGroup);
				questionsList.push(...[
					{
						...ques,
						isBreadcrumbCard: true,
						questions: [],
					},
					...breadcrumbQuestions,
				]);
			}
			else questionsList.push(ques);
		})
	}
	return questionsList;
}

const isReviewSection = (questionsList) => {
	if (questionsList && questionsList.length) {
		for(let i = 0; i < questionsList.length; i += 1) {
			if(questionsList[i].presentation_type === 'review') return true;
		}
	}
	return false;
}

function resetBreadcrumbData(action) {
	const { payload, type } = action;
	const currentStatus = get(
		payload,
		'other_params.current_status',
		'',
	);
	const questionObj = get(
		payload,
		'data.questionnarie.questions',
		{},
	);
	const layout = get(payload, 'data.questionnarie.questions.properties.layout.value');

	let allQuestions = [];
	allQuestions = get(
		payload,
		'data.questionnarie.questions.questions',
		[],
	);

	const isReviewStatus = currentStatus === 'review' || currentStatus === 'quotereview';

	if (isReviewStatus) {
		const questionsList = getFormattedReviewResponse(allQuestions);
		allQuestions = questionsList;
	}

	return allQuestions.map((questionItem, index) => {
		const {
			layoutRatio,
			leftQuestionList,
			rightQuestionList,
		} = getLeftAndRightData(questionItem, (type === GET_QUOTE_SUCCESS && layout !== 'two_columns') || isReviewStatus);
		const requiredSchema = HELPER.getRequiredSchema(
			rightQuestionList,
		);
		const isList = isListQuestion(
			rightQuestionList,
			isReviewStatus,
		);

		const originalQuestions = isList ? HELPER.getOriginalQuestions(questionItem) : [];
		const answersArray = HELPER.buildAnswersArray(rightQuestionList, originalQuestions);

		// check if section is hidden or all the questions in a section are hidden
		const isSectionHidden = HELPER.isQuestionGroupHidden(rightQuestionList);

		// Review Page Related Conditions
		const isReview = isReviewSection(rightQuestionList)
		const showReviewContinueButton = false;
		const listDisplay = getListDisplayMode(rightQuestionList);
		const questionStatus = getQuestionStatus(rightQuestionList);
		let enableContinue;
		if (isReviewStatus) {
			enableContinue = true;
		} else {
			enableContinue = HELPER.shouldEnableContinue(
				requiredSchema,
				answersArray,
				[],
				isList,
				listDisplay,
				questionStatus,
			);
		}
		return {
			id: HELPER.getRandomId(),
			isHidden: isSectionHidden,
			questionList: rightQuestionList,
			answeredListQuestion: HELPER.getAnsweredLisQuestionIds(rightQuestionList),
			leftQuestionList,
			layoutRatio,
			requiredSchema,
			answersArray,
			errorsArray: [],
			enableContinue,
			dataSelector: new Map(),
			otherParams: {
				...payload.other_params,
			},
			errors: {
				...payload.errors,
			},
			pageDesc: {
				...payload.page_desc,
			},
			review: {
				isReview,
				showReviewContinueButton,
			},
			/**
			 * Completed would be false for last section so last section
			 * avoid sending index _modify_flag=1 this issue is caused
			 * when resetBreadcrumbData called when answering reflexive
			 * question in the last section in the
			 * page NAT-1162
			 */
			completed: allQuestions.length - 1 !== index,
			list: {
				isList,
				listDisplay,
				hasItems: hasListItems(rightQuestionList),
				addButtonText: getAddButtonText(rightQuestionList),
				questionObj: questionItem,
				originalQuestions,
			},
			questionObj,
		};
	});
}

/**
 * Function to compare the masked question's response (e.g SSN) from the 
 * previous request and the upcoming response object.
 */
function compareMaskedResponse(breadcrumbQs, action) {
	let diff;
	let allQuestions = breadcrumbQs;
	const { payload, params, type } = action;
	const currentStatus = get(payload, 'other_params.current_status', '');
	
	const maskedQArray = get(params, 'maskedQArray', []);
	if (allQuestions.length === 0 || maskedQArray.length === 0) return false;
	
	const reviewStatus = currentStatus === 'review' || currentStatus === 'quotereview';
	allQuestions = getFormattedReviewResponse(allQuestions, reviewStatus);

	allQuestions.forEach(question => {
		const { rightQuestionList } = getLeftAndRightData(question, type === GET_QUOTE_SUCCESS || reviewStatus, payload);
		const isList = isListQuestion(rightQuestionList, reviewStatus);
		const originalQuestions = isList ? HELPER.getOriginalQuestions(question) : [];
		const answersArray = HELPER.buildAnswersArray(rightQuestionList, originalQuestions);
		if (answersArray.length) {
			maskedQArray.forEach(maskQ => {
				const maskedAnswers = rightQuestionList.filter(q => q.question_id === maskQ.question_id);
				if (maskedAnswers.length) {
					if (maskedAnswers[0].response !== maskQ.answer) diff = true;
				}
			})
		}
	})
	return diff;
}

/**
 * @description function validates different conditions to determine
 * if breadcrumb data should be reset.
 * @param {Array} currentBreadcrumbData 
 * @param {Object} action 
 */
function shouldReset(currentBreadcrumbData, action) {
	const { payload, params } = action;

	const currentStatus = get(payload, 'other_params.current_status', '');
	const role =  get(payload, 'other_params.role', '');

	const isReview = currentStatus === 'review' || currentStatus === 'quotereview';

	// Resets Breadcrumb Data for the following current_status routes
	const resetBreadcrumbRoutes = (currentStatus === 'quote' && role !== 'csr') || currentStatus === 'signature';

	const allQs = [];
	HELPER.flattenQuestions(get(payload, 'data.questionnarie.questions.questions', []), allQs);
	const isTabSection = currentStatus === 'questions' && role === 'csr' && allQs.some(q => q.question_type === 'tabs');

	const breadcrumbQs = get(payload, 'data.questionnarie.questions.questions', []);
	const breadcrumbQuestions = last(breadcrumbQs);

	// for masked questions, we need to rerender by comparing previous & upcoming responses
	let maskVariation = false;
	if (params.maskedQArray) {
		maskVariation = compareMaskedResponse(breadcrumbQs, action);
	}

	if (
		isReview ||
		resetBreadcrumbRoutes ||
		isTabSection ||
		currentBreadcrumbData.length === 0 ||
		maskVariation ||
		params.isReflexive ||
		params.validate ||
		params.breadcrumb_nav_flag ||
		params.prev_page_flag ||
		(breadcrumbQuestions && ('collapse' in breadcrumbQuestions)) ||
		breadcrumbQs.length < currentBreadcrumbData.length
	) {
		return true;
	}
	return false;
}

function updatedBreadcrumbData(currentBreadcrumbData = [], pageSeqNumber, action, routeChange = false) {
	const { payload, params, type } = action;

	if (shouldReset(currentBreadcrumbData, action) || routeChange) {
		return resetBreadcrumbData(action);
	}

	const questionObj = get(
		payload,
		'data.questionnarie.questions',
		{},
	);
	const currentStatus =  get(payload, 'other_params.current_status', '');
	/**
	 * If currentBreadcrumbData is an empty array and payload
	 * contains questions, then it means, user has refreshed
	 * the page. In this case all of the
	 * questions need to be copied, other attributes like
	 * required schema, answer
	 * array etc needs to be updated.
	 */
	const isReview = currentStatus === 'review' || currentStatus === 'quotereview';

	const breadcrumbQuestions = last(
		get(payload, 'data.questionnarie.questions.questions', null),
	);

	const layout = get(payload, 'data.questionnarie.questions.properties.layout.value')

	let questionList = [];
	if (breadcrumbQuestions) {
		let newBreadcrumbData = currentBreadcrumbData;
		const { isReflexive, sectionIndex } = params;
		if (isReflexive) {
			questionList = HELPER.insertReflexiveQuestion(
				breadcrumbQuestions,
			);
			newBreadcrumbData = HELPER.replaceSectionQuestion(
				currentBreadcrumbData,
				questionList,
				sectionIndex,
			);
			return newBreadcrumbData;
		}
		newBreadcrumbData = HELPER.removeDuplicate(
			currentBreadcrumbData,
			breadcrumbQuestions,
		);
		const {
			layoutRatio,
			leftQuestionList,
			rightQuestionList,
		} = getLeftAndRightData(
			breadcrumbQuestions,
			type === GET_QUOTE_SUCCESS && layout !== 'two_columns',
		);
		const requiredSchema = HELPER.getRequiredSchema(
			rightQuestionList,
		);
		const listDisplay = getListDisplayMode(rightQuestionList);
		let isList = false;
		const listError =  getQuestionStatus(rightQuestionList)
		if (Object.keys(layoutRatio).length === 0) {
			isList = isListQuestion(
				rightQuestionList,
				isReview,
			);
		}

		const originalQuestions = isList ? HELPER.getOriginalQuestions(breadcrumbQuestions) : [];
		const answersArray = HELPER.buildAnswersArray(rightQuestionList, originalQuestions);

		return [
			...newBreadcrumbData,
			{
				id: HELPER.getRandomId(),
				questionList: rightQuestionList,
				answeredListQuestion: HELPER.getAnsweredLisQuestionIds(rightQuestionList),
				leftQuestionList,
				layoutRatio,
				requiredSchema,
				answersArray,
				errorsArray: [],
				enableContinue: isReview ? true : HELPER.shouldEnableContinue(requiredSchema, [], [], isList, listDisplay, listError),
				otherParams: {
					...payload.other_params,
				},
				errors: {
					...payload.errors,
				},
				pageDesc: {
					...payload.page_desc,
				},
				list: {
					isList,
					listDisplay,
					hasItems: hasListItems(rightQuestionList),
					addButtonText: getAddButtonText(rightQuestionList),
					questionObj: breadcrumbQuestions,
					originalQuestions,
				},
				questionObj,
			},
		]
	}
	return currentBreadcrumbData;
}

function listSectionUpdates(state = {}, listQIds) {
	const { errorsArray, answersArray, questionList } = state;
	let _errorsArray = [];
	let _answersArray = [];
	const listError =  getQuestionStatus(questionList);
	_errorsArray = errorsArray.filter((item) => 
		!listQIds.includes(item.question_id),
	);
	_answersArray = answersArray.filter((item) => 
		!listQIds.includes(item.question_id),
	);
	return {
		...state,
		errorsArray: _errorsArray,
		answersArray: _answersArray,
		enableContinue: !listError && _errorsArray.length === 0,
	}
}

const timeStampUpdates = (state={}, action) => {
	const {
		questionId,
		timeStampData,
	} = action.payload
	const { transactionLog, lastSubmittedAt } = timeStampData
	const answersArray = HELPER.addOrReplace(state.answersArray, { question_id: questionId, transactionLog, lastSubmittedAt }, 'transaction_log' );
	const questionList = HELPER.findAndUpdateTimeStamp(state.questionList, questionId, transactionLog, lastSubmittedAt)
	return {
		...state,
		answersArray,
		questionList,
	}
}

function questionUpdates(state = {}, currentPageSeqNumber, action) {
	const {
		questionId,
		response,
		questionResponse,
		isError,
		isHidden,
		propQuestion,
		reset,
		isControlledInput,
		listParentId,
		listIndex,
		eSign,
		hasEdit,
		hasMask,
		timeStampData,
	} = action.payload;
	const answersArray = HELPER.addOrReplace(
		state.answersArray,
		{
			question_id: questionId,
			answer: response,
			immediateParent: propQuestion ? propQuestion.immediateParent : '',
			parentId: propQuestion ? propQuestion.parentId : '',
			parentListQuestionId: get(action, 'payload.propQuestion.properties.parent_list_id', ''),
			reset,
			listParentId,
			listIndex,
			hasEdit,
			hasMask,
			timeStampData,
		},
		'answer',
	);

	const nList = questionId.match(/_\$ureify_/g);
	const skipUpdate = nList && nList.length === 2;
	const questionList = skipUpdate ? state.questionList : HELPER.addOrReplace(state.questionList, {
		question_id: questionId,
		is_hidden: isHidden || false,
	}, 'is_hidden');
	const errorsArray = HELPER.addOrReplace(
		state.errorsArray,
		{
			question_id: questionId,
			isError,
		},
		'isError',
	).filter((errorElement) => errorElement && errorElement.isError);
	let enableContinue = true;
	let isCheckboxResponse = false;
	const invalidQs = questionList.filter(question => get(question, 'question_status_message', ''));
	if(invalidQs?.length){
		for(const eachQ of invalidQs){
			if((eachQ.question_id === questionId) && eachQ.display_type === 'checkbox' && response)
				isCheckboxResponse = true;
		}
	}
	/**
	 * isError is undefined when the page is reloaded
	 * disable continue if we there's any invalid question
	 */
	if (!isError && questionList && !isCheckboxResponse) {
		invalidQs.forEach(question => {
			// when page is refreshed, push existing invalid questions into errorsArray
			if (isError === undefined && !errorsArray.find(errQ => errQ.question_id === question.question_id)) {
				errorsArray.push({
					question_id: question.question_id,
					isError: true,
					hasEdit,
					hasMask,
				});
			}
			// check if default invalid questions are answered as valid, if not make continue button disabled
			if (errorsArray.find(errQ => errQ.question_id === question.question_id)) {
				enableContinue = false;
			}
		});
	}

	const currentStatus = get(
		action,
		'payload.other_params.current_status',
		'',
	);
	const isList = isListQuestion(
		state.questionList,
		currentStatus === 'review',
	);
	const listDisplay = getListDisplayMode(state.questionList);
	const listError = getQuestionStatus(state.questionList);

	switch (action.type) {
		case UPDATE_QUESTION_ANSWER:
			return {
				...state,
				questionList: HELPER.findAndUpdateResponse(questionList, questionId, response, questionResponse, isControlledInput),
				enableContinue: enableContinue && HELPER.shouldEnableContinue(state.requiredSchema, answersArray, errorsArray, isList, listDisplay, listError, eSign),
				enableAdd: HELPER.shouldEnableContinue(state.requiredSchema, answersArray, errorsArray),
				answersArray,
				errorsArray,
			};
		default:
			return state;
	}
}

function getSelectorOptions( parentQuestion, currentSelectedOptions, currentOption) {
	const responseOptions = get(parentQuestion.questions[0], 'response_options', []);
	const currentQuestionId = get(parentQuestion.questions[0], 'question_id', '');
	const sortedOption = [...currentOption].sort(HELPER.getSortOrder('target_question_id'));
	let isPresent = false;
	let dataSelectorOptions = currentSelectedOptions.get(currentQuestionId);
	let selectorIndex = -1;
	let count = 0;
	responseOptions.forEach(response => {
		count = 0;
		const sortedResponse = [...response].sort(HELPER.getSortOrder('target_question_id'));
		for(let index = 0; index < sortedResponse.length; index+=1) {
			if(sortedResponse[index].response.value === sortedOption[index].response.value ){
				count +=1;
			}
		}
		if(count === sortedOption.length){
			isPresent = true;
		}
	})
	if(Array.isArray(dataSelectorOptions)) {
	 dataSelectorOptions.forEach((dataSelector, index) => {
		count = 0;
		const sortedResponse = [...dataSelector].sort(HELPER.getSortOrder('target_question_id'));;
		if(sortedResponse.length === currentOption.length) {
			for(let i = 0; i < sortedResponse.length; i+=1) {
				if(sortedResponse[i].target_question_id === sortedOption[i].target_question_id ){
					count +=1;
				}
			}
			if(count === sortedOption.length){
				selectorIndex = index;
			}
		}
	});
   }
   else {
	   dataSelectorOptions = [];
   }
	if(selectorIndex > -1){
		dataSelectorOptions.splice(selectorIndex, 1);
	}
	if(!isPresent) {
		dataSelectorOptions.push(currentOption);
		currentSelectedOptions.set(currentQuestionId, dataSelectorOptions);
	}
	return currentSelectedOptions;
}

function updateDataSelectorOptions( currentSection, action ) {
   let currentDataSelector = currentSection.dataSelector;
   const currentOption = [];
   const { parentQuestion } = action.payload;
   const selectorOptions = get(parentQuestion.questions[0], 'selector_options', []);
   selectorOptions.forEach(option => {
	 const answersArray = HELPER.getAnswersArray(currentSection.answersArray, option.id);
	 if(answersArray.length > 0){
		currentOption.push({ target_question_id: option.id, response: { value : answersArray[0].answer } });
	 }
   });
   currentDataSelector = getSelectorOptions(parentQuestion, currentDataSelector, currentOption);
   const updatedSection = { ...currentSection };
   updatedSection.dataSelector = currentDataSelector;
   return updatedSection;
}
 function raiseAlert(nonCleanResponse){
	window.alert(JSON.stringify(nonCleanResponse));
 }
export default function questions(state = initialState, action) {
	let currentBreadcrumb = null;
	let breadcrumbs = {};
	let currentPageSeqNumber = -1;
	let prevSections = [];
	let updatedSectionQuestions;
	let popupQuestions = [];
	let subSectionId;
	let actionMessage = {};
	let bannerInfo = '';
	let bannerTooltip = '';
	let footerNotes = [];
	let popupDialog = {};
	let custCareTel='';
	let trustPilotData='';
	let policyExpiry='';
	let reviseButtonLabel='';
	let footerBanner=null;
	let nonCleanResponse=null;
	let role='';
	const csrConfig = {
		rateClass: {
			enable: false,
			show: true,
		},
		quoteResults: {
			enable: false,
			show: true,
		},
		paymentOptions: {
			enable: false,
			show: true,
		},
	};
	const finalQuoteCsrConfig = {
		rateClass: {
			enable: false,
			show: true,
		},
		quoteResults: {
			enable: false,
			show: true,
		},
		paymentOptions: {
			enable: false,
			show: true,
		},
	};
	let { lastFocusedElementId } =  state;
	let routeChange = false;
	let signatureRedirect = '';
	const triggerValidation = [];
	const attempt = sessionStorage.getItem('attempt') || 0
	switch (action.type) {
		case GET_NEW_QUESTION_START:
			const isReflexOrNextCall = get(action, 'params.reflexive_question_flag', 0) === 1 || get(action, 'params.next_page_flag', 0) === 1;
			return {
				...state,
				isLoading: true,
				reload:action.params.reload,
				actionMessage: action.params.actionMessage,
				loadingSection: action.params.sectionIndex,
				isReflexive: action.params.isReflexive,
				validate: action.params.validate,
				isListAddAction: action.params.isListAddAction,
				breadcrumbNavigation: action.params.breadcrumbNavigation,
				maskedQArray: action.params.maskedQArray,
				lastSectionSubmittedAt: isReflexOrNextCall ? getUTCTimeStamp() : state.lastSectionSubmittedAt,
			}
		case GET_NEW_QUESTION:
		case GET_QUESTIONS_SUCCESS:
		case GET_DOCUMENT_SUCCESS:
			bannerInfo = get(action, 'payload.data.questionnarie.questions.header', '');
			bannerTooltip = get(action, 'payload.data.questionnarie.questions.header_tooltip', '');
			footerNotes = get(action, 'payload.data.questionnarie.questions.footer_notes', []);
			popupDialog = get(action, 'payload.data.questionnarie.questions.popup_dialog', {});
			custCareTel=get(action,'payload.data.questionnarie.questions.cust_care_tel','');
			policyExpiry=get(action,'payload.data.questionnarie.questions.policy_expiry_date','');
			reviseButtonLabel=get(action,'payload.data.questionnarie.questions.revise_button_text','');
			footerBanner=get(action,'payload.data.questionnarie.questions.footer_banner',null);
			nonCleanResponse=get(action,'payload.data.questionnarie.questions.non_clean_response',null);
			role=get(action,'payload.other_params.role','');
			popupQuestions = get(action, 'payload.data.popup_questions', []);

			if(nonCleanResponse){
				raiseAlert(nonCleanResponse);
			}
			 
			breadcrumbs = updateBreadcrumbs(
				state.breadcrumbs,
				get(action, 'payload.breadcrumbs'),
			);
			currentBreadcrumb = breadcrumbs.currentBreadcrumb;
			currentPageSeqNumber = get(action, 'payload.data.questionnarie.page_sequence_number', -1);
			routeChange = state.activeSubsectionId !== currentBreadcrumb.id;
			if (routeChange) {
				lastFocusedElementId = '';
				// reset attempts if prev route is challenge and latest route is other one
				if(state.activeSubsectionId === 'challenge' && attempt > 0) {
					window.sessionStorage.setItem('attempt', 0)
				}
			}
			return {
				...state,
				lastFocusedElementId,
				routeChange,
				bannerInfo,
				bannerTooltip,
				footerNotes,
				popupDialog,
				custCareTel,
				policyExpiry,
				reviseButtonLabel,
				footerBanner,
				role,
				csrConfig,
				triggerValidation,
				finalQuoteCsrConfig,
				currentPageSeqNumber,
				breadcrumbs,
				popupQuestions,
				activeSubsectionId: currentBreadcrumb.id,
				layout: get(action, 'payload.data.questionnarie.questions.properties.layout.value', 'double') === 'one_column' ? 'single' : '',
				sections: {
					...state.sections,
					[currentBreadcrumb.id]: updatedBreadcrumbData(
						state.sections[currentBreadcrumb.id],
						currentPageSeqNumber,
						action,
						routeChange,
					),
				},
				isLoading: false,
			};
		case GET_QUOTE_SUCCESS:
		case GET_PLAN_SUCCESS:
		case GET_REVIEW_SUCCESS:
		case GET_QUOTEREVIEW_SUCCESS:
		case GET_SIGNATURE_SUCCESS:
		case GET_PAYMENT_SUCCESS:
		case GET_TIMEOUT_SUCCESS:
		case GET_INACTIVESESSION_SUCCESS:
		case GET_CHALLENGE_SUCCESS:
		case GET_RIDERS_SUCCESS:
		case GET_COMPLETED_SUCCESS:
		case GET_LOGIN_SUCCESS:
		case GET_KNOCKOUT_SUCCESS:
		case GET_NOQUOTE_SUCCESS:
		case GET_NONCLEAN_SUCCESS:
		case GET_UNAUTHORIZE_SUCCESS:
		case GET_NOTFOUND_SUCCESS:
			bannerInfo = get(action, 'payload.data.questionnarie.questions.header', '');
			bannerTooltip = get(action, 'payload.data.questionnarie.questions.header_tooltip', '');
			footerNotes = get(action, 'payload.data.questionnarie.questions.footer_notes', []);
			popupDialog = get(action, 'payload.data.questionnarie.questions.popup_dialog', {});
			custCareTel=get(action,'payload.data.questionnarie.questions.cust_care_tel','');
			trustPilotData=get(action,'payload.data.questionnarie.questions.nonemployee_trustbox_text','');
			policyExpiry=get(action,'payload.data.questionnarie.questions.policy_expiry_date','');
			reviseButtonLabel=get(action,'payload.data.questionnarie.questions.revise_button_text','');
			footerBanner=get(action,'payload.data.questionnarie.questions.footer_banner',null);
            nonCleanResponse=get(action,'payload.data.questionnarie.questions.non_clean_response',null);
			role=get(action,'payload.other_params.role','');
			popupQuestions = get(action, 'payload.data.popup_questions', []);

            if(nonCleanResponse){
				raiseAlert(nonCleanResponse);
			}
			subSectionId = get(action,'payload.other_params.current_status', '');
			currentPageSeqNumber = get(action, 'payload.data.questionnarie.page_sequence_number', -1);
			routeChange = state.activeSubsectionId !== subSectionId;
			if (routeChange) {
				lastFocusedElementId = '';
				// reset attempts if prev route is challenge and latest route is other one
				if(state.activeSubsectionId === 'challenge' && attempt > 0) {
					window.sessionStorage.setItem('attempt', 0)
				}
			}
			signatureRedirect = get(action, 'payload.other_params.next_button_redirect_url', '');
			return {
				...state,
				lastFocusedElementId,
				routeChange,
				bannerInfo,
				bannerTooltip,
				footerNotes,
				custCareTel,
				trustPilotData,
				policyExpiry,
				reviseButtonLabel,
				footerBanner,
				role,
				csrConfig,
				triggerValidation,
				finalQuoteCsrConfig,
				signatureRedirect,
				activeSubsectionId: subSectionId,
				currentPageSeqNumber,
				breadcrumbs,
				popupQuestions,
				sections: {
					...state.sections,
					[subSectionId]: updatedBreadcrumbData(
						state.sections[subSectionId],
						currentPageSeqNumber,
						action,
						routeChange,
					),
				},
				isLoading: false,
				popupDialog,
			};
		case UPDATE_QUESTION_ANSWER:
			prevSections = state.sections;
			updatedSectionQuestions = questionUpdates(
				state.sections[action.activeSubsectionId][
					action.payload.sectionIndex
				],
				state.currentPageSeqNumber,
				action,
			);
			// 'index' is used for updating the
			// last question and answer in a section
			prevSections[action.activeSubsectionId][
				action.payload.sectionIndex
			] = updatedSectionQuestions;

			// trigger field validation based on trigger_validation key from response
			const triggerQs = [];
			if (action.payload.triggerValidation && Array.isArray(action.payload.triggerValidation)) {
				action.payload.triggerValidation.forEach((triggerQ) => {
				const subSections = state.sections[action.activeSubsectionId];
				const answersArray = subSections?.[action.payload.sectionIndex]?.answersArray || [];
				const answQ = answersArray.find((q) => q.question_id === triggerQ);
				if (answQ && answQ.answer) {
					triggerQs.push(triggerQ);
				}
				});
			}

			return {
				...state,
				sectionIndex:action.payload.sectionIndex,
				sections: prevSections,
				csrConfig: {
					...state.csrConfig,
					...action.payload.csrConfig,
				},
				triggerValidation: triggerQs,
				finalQuoteCsrConfig: {
					...state.finalQuoteCsrConfig,
					...action.payload.finalQuoteCsrConfig,
				},
			};
		case UPDATE_QUESTION_ANSWER_TIME_STAMP:
				prevSections = state.sections;
				updatedSectionQuestions = timeStampUpdates(
					state.sections[action.payload.activeSubsectionId][
						action.payload.sectionIndex
					],
					action,
				);
				// 'index' is used for updating the
				// last question and answer in a section
				prevSections[action.payload.activeSubsectionId][
					action.payload.sectionIndex
				] = updatedSectionQuestions;
				return {
					...state,
					sections: prevSections,
					lastInteractedQuestion: HELPER.findAndGetTimeStamp(updatedSectionQuestions.questionList, action.payload.questionId),
				};
		case UPDATE_LIST_SECTION:
			prevSections = state.sections;
			updatedSectionQuestions = listSectionUpdates(
				state.sections[action.activeSubsectionId][
					action.payload.sectionIndex
				],
				action.payload.listQIds,
			)
			prevSections[action.activeSubsectionId][
				action.payload.sectionIndex
			] = updatedSectionQuestions;
			return {
				...state,
				sections: prevSections,
			}
		case UPDATE_DATA_SELECTOR_OPTIONS:
            prevSections = state.sections;
			updatedSectionQuestions = updateDataSelectorOptions(state.sections[action.activeSubsectionId][
				action.payload.sectionIndex
			], action)
			return {
				...state,
				sections: prevSections,
			}
		case API_IN_PROGRESS:
			return {
				...state,
				isLoading: true,
				loadingSection: 0,
			}
		case SET_CURRENT_BREADCRUMB:
			return {
				...state,
				breadcrumbs: {
					...state.breadcrumbs,
					currentBreadcrumb: getCurrentBreadcrumb(
						state.breadcrumbs.breadcrumbList,
						action.id,
					),
					breadcrumbList: state.breadcrumbs.breadcrumbList.map(
						(breadcrumb) => {
							if (breadcrumb.id === action.id) {
								return {
									...breadcrumb,
									active: true,
								};
							}
							return {
								...breadcrumb,
								active: false,
							};
						},
					),
				},
			};
		case 'SET_LIST_INDEX':
			return {
				...state,
				listIndex: action.index,
			}
		case 'SET_REFLEXIVE_LIST_INDEX':
			return {
				...state,
				listEditIndex: action.index,
				listEditQId: action.id,
			}
		case SET_ACTION_SUCCESS_MESSAGE:
			actionMessage = get(action, 'payload.actionMessage', {
				type: '',
				message: '',
			});
			return {
				...state,
				actionMessage: {
					type: actionMessage.type,
					message: actionMessage.message,
				},
			}
		case GET_QUESTION_FAILURE:
		case SET_ACTION_FAILURE_MESSAGE:
			actionMessage = get(action, 'payload.actionMessage', {
				type: '',
				message: '',
			});
			return {
				...state,
				isLoading: false,
				actionMessage: {
					type: actionMessage.type,
					message: actionMessage.message,
				},
			};	
		case TOGGLE_ESIGN_BUTTON:
			return {
				...state,
				eSign: {
					hasESign: true,
					eSignEnable: action.enable,
				},
			}
		case UPDATE_LAST_FOCUSED_ELEMENT_ID:
			return {
				...state,
				lastFocusedElementId: get(action, 'payload.id', ''),
			}
		case SET_POPUP_DETAILS:
			return {
				...state,
				popupDetails: action.payload,
			}
		default:
			return state;
	}
}
