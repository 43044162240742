/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef } from 'react';
import get from 'lodash.get';
import { connect } from 'react-redux';
import styled, { withTheme } from 'styled-components';
import { Tooltip } from 'crux';
import { getFirstUnansweredQuestion, getLastAnsweredQuestion } from '../../redux/actions/questions';
import { doFormotivAction, scrollQuestionIntoView } from '../../utils';

const CustomHeaderWrapper = styled.div`
  background: #00534c;
  height: 80px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #E4E2DB;
  ${({ headerPosition, role })=>{
    return headerPosition === 'fixed' && role !== 'csr' ? 'position: fixed; top: 0; left: 0; width: 100%; z-index: 999;' : '';
  }}
  @media (forced-colors: active) {
    border-bottom: 1px solid WindowText;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  @media( max-width:425px){
    margin: ${({ bannerInfo }) => bannerInfo ? '0 5px' : '0 0 0 24px'};
  }
`;

const CustomLogoWrapper = styled.div`
  width: 33%;
  margin-top: 0;
  position: relative;
  max-width: 280px;
  margin: 0 20px;
  @media (max-width: 425px) {
    margin: 0;
    width: 50%;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    max-width: 162px;
  }
  & a {
    width: 100%;
    margin: 0 20px;
    border: none;
    @media (min-width: 1025px) and (max-width: 1040px) {
      margin: 0 5px;
    }
    @media (min-width: 1040px) and (max-width: 1060px) {
      margin: 0 15px;
    }
    @media (max-width: 768px) {
      margin: 0;
    }
  }
  & img {
    width: 162px;
    height: 65px;
    @media (max-width: 768px) {
      max-width: 162px;
    }
  }
`

const Banner = styled.div`
  width: 67%;
  margin: 0 40px;
  max-width: 980px;
  padding: 10px 0;
  padding-left: 55px;
  font-family: 'Avenir-Medium';
  font-display: swap;
  line-height: 27.32px;
  font-size: 20px;
  color: ${({ bannerStyles }) => bannerStyles.color};
  font-weight: ${({ bannerStyles }) => bannerStyles.fontWeight};
  ${({ alignBanner, role }) =>  alignBanner === 'right' && role !== 'csr' ? 'width: 100%; text-align: right' : 'text-align: left'};
  ${({ bannerInfo, bannerTooltip }) => bannerInfo && bannerTooltip && `
    display: flex;
    justify-content: flex-end;
  `}
  @media (min-width: 768px) and (max-width: 1024px) {
    margin: 0 20px 0 0;
    width: 75%;
    padding-right: 25px;
  }
  @media (max-width: 980px) {
    ${({ alignBanner, role }) => alignBanner === 'right' && role !== 'csr' ? '' : 'text-align: left; margin-left: 20px;'};
    font-size: 20px;
    padding-left: 0;
    padding-right: 25px;
  }
  @media (min-width: 426px) and (max-width: 558px) {
    margin: 0;
    width: 75%;
    font-size: 18px;
    padding-right: 25px;
    line-height: 1;
  }
  @media(max-width: 425px){
    font-size: 16px;
    margin: 0;
    width: 50%;
    line-height: 1;
    padding-left: 0;
    padding-right: 3px;
  } 
`;

const BannerTooltip = styled.span`
  margin-left: 5px;
  font-size: 18px;
`;

const SkipNavLink = styled.a`
  background: ${({ buttonBgColor }) => buttonBgColor};
  color: black;
  padding: 0.5rem 1.5rem;
  border: 2px solid white;
  border-radius: 0 0 0.25rem 0.25rem;
  left: 0.5rem;
  position: absolute;
  transform: translateY(-240%);
  transition: transform 325ms ease-in;
  :focus,
  :hover{
    cursor: pointer;
    transform: translateY(-55%);
    text-decoration: underline;
    outline: 2px solid black;
  }
  &.skip-nav-link:hover{
    color: white;
  }
`
const LogoStyles = styled.img`
  @media (-ms-high-contrast: active), (forced-colors: active) {
  & {
    filter: invert(63%) sepia(64%) saturate(4611%) hue-rotate(135deg) brightness(90%) contrast(101%);
  }
`

const fetchLastAnsweredQuestion = ({
  isAccordionExpanded = false,
  questions,
  pageId,
}) => {
  let el;
  const { answerAnsweredQuestion: lastAsnweredQ, displayType, listQ } = getLastAnsweredQuestion({ questions }, pageId, isAccordionExpanded);
  el = document.getElementById(lastAsnweredQ?.question_id) || document.querySelector(`[id*="${lastAsnweredQ?.question_id}"]`);

  if(displayType?.match(/checkbox/i)){
    const checkboxOptionsLen = lastAsnweredQ?.answer?.length;
    const selectedCheckboxId = checkboxOptionsLen && `${lastAsnweredQ?.question_id}-${lastAsnweredQ?.answer[checkboxOptionsLen - 1]}`;
    el = document.querySelector(`[id*="option-${selectedCheckboxId || lastAsnweredQ?.question_id}"]`) 
  }

  if(displayType?.match(/radio/i)){
    const selectedRadioBtnId = lastAsnweredQ?.answer && `${lastAsnweredQ?.answer}`;
    el = document.querySelector(`input[id*="${selectedRadioBtnId || lastAsnweredQ?.question_id}"]`) 
  }

  if(listQ?.display_type === 'list' && !listQ?.listEditMode){
    el = document.querySelector('div[id*="list-item-card-wrapper"] > div:first-child > div:first-child > div:last-child > div > div > button')
  }

  return el;
}

const fetchFirstUnansweredQuestion = ({
  isAccordionExpanded,
  questions,
  pageId,
}) => {
  let el;
  const unansweredQ = getFirstUnansweredQuestion({ questions }, true, pageId, isAccordionExpanded);
  el = document.getElementById(unansweredQ?.question_id) || document.querySelector(`[id*="${unansweredQ?.question_id}"]`);

  if(unansweredQ?.display_type?.match(/radio/i))
    el = document.querySelector(`input[id*="${unansweredQ?.question_id}"]`) 
  
  if(unansweredQ?.display_type?.match(/checkbox/i))
    el = document.querySelector(`[id*="option-${unansweredQ?.question_id}"]`) 

  return el;
}

const skipLinkQsHandler = ({
  isAccordionExpanded,
  questions,
  pageId,
}) => {
    let el;
    el = fetchFirstUnansweredQuestion({ isAccordionExpanded, pageId, questions })

    if(!el)
      el = fetchLastAnsweredQuestion({ isAccordionExpanded, pageId, questions });
    
    return el;
}

const CustomHeader = ({
  bannerStyles,
  bannerInfo,
  bannerTooltip,
  questions,
  isSmallScreen,
  branding,
  pathname,
  layout,
  pageId,
  role,
  theme,
}) => {
  const globalHeaderTheme =  get(theme, ['config', 'theme', 'pageOverrides', pageId, 'globalHeaderTheme'], {});
  const { config: { theme: { global: { headerBackground, footerBanner: { button: { selectedBgColor: buttonBgColor } } } } } } = theme;

  // Navigation with tab key
	const focusRef = useRef();
	useEffect(() => {
		if (focusRef && focusRef.current && focusRef.current.focus && typeof focusRef.current.focus === 'function') {
      focusRef.current.focus();
      /* As per requirement want the logo to be focused after each breadcrumb continue */
      // setTimeout(() => {
      //   document.activeElement.blur();
      // }, 0);
		}
	}, [pathname]);

  const skipNavigationHandler = () => {
    let isAccordionExpanded

    const accordionElement = document.querySelector('div[name="accordion-wrapper"] > div:first-child')
    isAccordionExpanded = accordionElement?.getAttribute('aria-expanded');
    isAccordionExpanded = (isAccordionExpanded === 'true');

    const el = skipLinkQsHandler({ questions, pageId, isAccordionExpanded })

    if(el){
      const elTop = el.getBoundingClientRect().top + window.scrollY - (globalHeaderTheme.headerPosition === 'fixed' ? 160 : 100) - (isSmallScreen ? 150 : 0) - 100;
      window.scroll({
        top: elTop,
        behavior: 'smooth',
      });
      setTimeout(() => { 
        el.focus({ preventScroll:true })
      }, 100);
    }
  }

  const handleSkipLinkOnKeydown = (event) => {
    const eventKey = event?.key
    if (eventKey === 'Enter') {
      const element = document.querySelector('.skip-nav-link')
      element.click();
    }
  }

  const handleFocus = (e) => {
    const element = e.target;
    scrollQuestionIntoView({
      element,
      theme,
      pageId: theme.pageId,
      deviceType: theme.deviceType,
    });
  };

  const { headerPosition, alignBanner } = globalHeaderTheme;
  // Override role specific alignment for knockout and nonclean page
  // eslint-disable-next-line no-param-reassign
  role = ['knockout', 'nonclean'].includes(pageId) ? '' : role;

  return (
    <CustomHeaderWrapper name="custom-header-wrapper" pageId={ pageId } role={ role } headerPosition={ headerPosition } style={ { background: headerBackground } }>
      {
        !isSmallScreen && (
          <SkipNavLink
            buttonBgColor={ buttonBgColor }
            className="skip-nav-link"
            tabIndex="0"
            onClick={ () => skipNavigationHandler() }
            onKeyDown={ handleSkipLinkOnKeydown }
          >
            Skip To Main Content
          </SkipNavLink>
        )
      }
      <HeaderContainer name="custom-header-container" bannerInfo={ bannerInfo }>
        <CustomLogoWrapper name="custom-logo">
          <a href={ branding.link } target="_blank" ref={ focusRef } rel="noopener noreferrer">
            <LogoStyles
              src={ layout === 'double' ? branding.darkLogo : branding.lightLogo }
              alt={ `${branding.altText}` }
            />
          </a>
        </CustomLogoWrapper>
        <Banner
          name="custom-header-banner"
          bannerStyles={ bannerStyles }
          alignBanner={ alignBanner }
          role={ role }
          bannerInfo={ bannerInfo }
          bannerTooltip={ bannerTooltip }
        >
          {bannerInfo && (
            <div
              onClick={ () => {
                const plainText = bannerInfo?.replace(/<[^>]+>/g, '');
                doFormotivAction('inputActivity', false, {
                  name: 'Need Help',
                  type: 'link',
                  value: plainText?.split(' ')[2],
                });
              } }
              dangerouslySetInnerHTML={ { __html: bannerInfo } }
            />
          )}
          {bannerInfo && bannerTooltip && <BannerTooltip>
            <Tooltip
                ariaLabel="Need Help info dialog"
                tooltipTitle=''
                name="tool-tip"
                tooltipHTML={ bannerTooltip }
                hasTooltip
                onTooltipFocus={ handleFocus }
                removeAriaLabelforPopper
                ariaLiveMode
                fallbackPlacements={ ['left'] }
                tooltipId="header-telephone-popper-wrapper"
            />
           </BannerTooltip>
           }
        </Banner>
      </HeaderContainer>
    </CustomHeaderWrapper>
  );
};

function mapStateToProps({ questions, router, meta }) {
	const pathname = get(router, 'location.pathname', '');
  return {
    bannerInfo: questions.bannerInfo,
    bannerTooltip: questions.bannerTooltip,
    role:questions.role,
    pathname,
    questions,
    isSmallScreen: meta.isSmallScreen,
  };
}
export default withTheme(connect(mapStateToProps)(CustomHeader));
