import styled from 'styled-components';

export const PdfWrapper = styled.div`
    margin-top: 10px;
    text-align: center;

    @media only screen and (min-width: 320px) and (max-width: 480px) {
        margin-right: 0;
    }

    @media only screen and (min-width: 481px) and (max-width: 767px) {
        margin-right: 1px;
    }

    @media only screen and (min-width: 768px) and (max-width: 1024px) {
        margin-right: 20px;
    }
`;

export const Wrapper = styled.div`
    display: flex;
    justify-content: center;
`;

export const PageLabel = styled.p`
    margin-top: 20px;
    color: ${({ color }) => color};
`;

export const PdfDisplayWrapper = styled.div`
    max-width: 1100px;
    max-height: 500px;
    overflow-y: auto;
    border: 2px solid #cccccc60;
    ${({ pdfTheme }) => pdfTheme && `
        & .react-pdf__Document {
            max-height: ${pdfTheme.maxHeight};
            &:focus {
                outline: ${pdfTheme.borderOnFocus};
            }
        }
        width: ${pdfTheme.width}
    `}
`;