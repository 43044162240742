import React from 'react';
import styled, { withTheme } from 'styled-components';
import { Card } from 'crux';
import ScrollToTop from '../ScrollToTop';

const LoaderWrapper = styled.div`
	margin-top: 120px;
	margin-bottom: 40px;
	div {
		min-height: 560px;
	}
	video,
	img {
		max-width: 425px;
		margin: 0 auto;
		display: block;
		margin-top: -50px; // move it up a bit.
	}
	p {
		margin: 0;
		padding: 50px;
		font-size: 36px;
		font-weight: 500;
		color: rgba(71, 100, 126, 1);
		text-align: center;
	}
	@media (min-width: 481px) and (max-width: 768px) {
		p {
			padding: 22px 0 0 0;
		}
		video,
		img {
			max-width: 100%;
			height: auto;
			padding: 30px;
		}
	}
	@media (min-width: 321px) and (max-width: 480px) {
		p {
			font-size: 24px;
			padding: 22px 0 0 0;
		}
		video,
		img {
			max-width: 100%;
			height: auto;
		}
	}
	@media (max-width: 320px) {
		p {
			font-size: 16px;
			padding: 22px 0 0 0;
		}
		video,
		img {
			padding: 0px;
			max-width: 100%;
			height: auto;
		}  
	}
`;

const Loader = (props) => {
	const { theme : {
		config: {
			global: {
				loaderWebp,
			},
		},
	} } = props;

	return (
		<ScrollToTop>
			<LoaderWrapper>
				<Card>
					{/* Changing loader from webm i.e. media to image format */}
					<img src={ loaderWebp } alt="Page Loading" />
					{/* <video muted autoPlay loop playsInline>
						<source src={ loaderWebm } type="video/webm" />
  						<source src={ loaderMp4 } type="video/mp4" />
					</video> */}
					<p>Just a moment...</p>
				</Card>
			</LoaderWrapper>
		</ScrollToTop>
	);
};

export default withTheme(React.memo(Loader));
