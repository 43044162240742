/* eslint-disable no-console */
import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import { BrowserRouter } from 'react-router-dom';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './index.css';
// import * as serviceWorker from './serviceWorker';
import log from './logger';
import Main from './Main';
import config from './config';

log();

const currentDomain = window.location.hostname.replace('www.','');
console.log('currentDomain: ', currentDomain);
console.log('REACT_APP_URL_UPDATE: ', process.env.REACT_APP_URL_UPDATE);


if (process.env.REACT_APP_URL_UPDATE === 'TRUE' &&  currentDomain === process.env.REACT_APP_OLD_DOMAIN) {
    const queryParams = window.location.search;
    console.log('currentDomain params: ', queryParams)
    const redirectUrl = process.env.REACT_APP_REDIRECT_DOMAIN+queryParams;
    window.location.href = redirectUrl;
} else if (config) {
  ReactDOM.render(
    <BrowserRouter>
      <Main config={ config } />
    </BrowserRouter>,
    document.getElementById('root'),
  );
} else {
  window.location.href = '/page-not-found.html';
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
