import React from 'react';
import styled from 'styled-components';

const FooterMainStyles = styled.div`
  & {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 85%;
    font-family: Arial, sans-serif;
    font-display: swap;
    font-size: 15px;
    color: black;
  }
  .policy-statement > a {
    text-decoration: underline;
  }
`;

const ProcessFooterString = () => {
  return (
    <FooterMainStyles class="footer-main">
      <div className="policy-statement">
		An official Amica Insurance site. © 2015-2024 Amica Life Insurance Company, Lincoln, Rhode Island. All Rights Reserved. | <a href="https://www.amica.com/en/privacy-security.html" target="_blank" rel="noopener noreferrer">Privacy Policy </a>
      </div>
    </FooterMainStyles>
  );
};

export default ProcessFooterString;