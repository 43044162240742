import get from 'lodash.get';
import { getDeviceType , getRoleAndPageType } from '../../utils';
import { SET_SMALL_SCREEN, SET_LIST_RELATION ,SET_DEVICE_TYPE } from '../actions/meta';
import HELPER from './helper';

const getIsSmallScreen = () => {
  const { role, pageType } = getRoleAndPageType();
  return !role && pageType === 'questions'
    ? window.innerWidth < 900
    : window.innerWidth < 768;
};

const initialState = {
  userAgent: HELPER.getBrowser(window.navigator.userAgent),
  os: HELPER.getOperatingSystem(window),
  platform: HELPER.getDeviceType(),
  isSmallScreen: getIsSmallScreen(),
  listRelation: {},
  deviceType: getDeviceType(window.innerWidth),
};

const getRelation = (question) => {
  const pId = get(question, 'properties.parent_list_id', '');
  const qId = question.question_id.split('_$ureify_')[1];
  return {
    [qId]: pId,
  }
}

function updateListRelation(listRelation, payload) {
  const { question, activeSubsectionId } = payload;
  if (listRelation[activeSubsectionId]) {
   return {
     ...listRelation,
     [activeSubsectionId]: {
       ...listRelation[activeSubsectionId],
       ...getRelation(question),
     },
   }
  }
  return {
    ...listRelation,
    [activeSubsectionId]: getRelation(question),
  }
}

function meta(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_SMALL_SCREEN:
      return {
        ...state,
        isSmallScreen: payload,
      };
    case SET_LIST_RELATION:
      return {
        ...state,
        listRelation: updateListRelation(state.listRelation, action.payload),
      }
    case SET_DEVICE_TYPE:
      return {
        ...state,
        deviceType: payload,
      }
    default:
      return state;
  }
}

export default meta;
