import React, { useCallback, useEffect, useState } from 'react';
import get from 'lodash.get';
import { connect } from 'react-redux';
import { Button } from 'crux';
import { withTheme } from 'styled-components';
import LocalStorageServices from '../../util/localStorageServices';
import { HeaderWrapper, Links, Logo, ButtonWrapper } from './styles';
import CustomHeader from './CustomHeader';
import { removeAuthHeaders } from '../../redux/actions/authHandler';
import { isIe } from '../../utils';

const Header = (props) => {
  const {
    layout,
    background,
    isSmallScreen,
    loading,
    handleLogout,
    pageId,
    customHeader: { isPresent, ...customHeader } = {},
  } = props;
  const [fade, setFade] = useState(false);
  const scrollListener = useCallback(() => {
    if (isSmallScreen) {
      return;
    }
    if (window.scrollY > 50) {
      setFade(true);
    } else {
      setFade(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', scrollListener);
    return () => {
      window.removeEventListener('scroll', scrollListener);
    };
  }, []);

  const branding = get(props, 'theme.config.branding', {});
  const hasLoginEnabled = get(
    props,
    'theme.config.features.login.enable',
    false,
  );
  const hasLogoutButton =
    hasLoginEnabled && !loading && LocalStorageServices.getAccessToken();
  return (
    <>
      {isPresent ? (
        <CustomHeader
          bannerStyles={ { ...customHeader.banner } }
          branding={ branding }
          layout={ layout }
          pageId={ pageId }
        />
      ) : (
        <>
          <HeaderWrapper
            name="header-wrapper"
            isSmallScreen={ isSmallScreen }
            background={ background }
            layout={ layout }
            fade={ fade }
            isIe={ isIe() }
          >
            <Logo isSmallScreen={ isSmallScreen }>
              <a href={ branding.link } target="_blank" rel="noopener noreferrer">
                <img
                  src={
                    layout === 'double' ? branding.darkLogo : branding.lightLogo
                  }
                  alt={ branding.altText }
                />
              </a>
            </Logo>
            <Links />
          </HeaderWrapper>
          {hasLogoutButton && (
            <ButtonWrapper>
              <Button
                shape="rectangle"
                label="Logout"
                type="outline"
                color="blue"
                alignment="center"
                handleButtonClick={ handleLogout }
              />
            </ButtonWrapper>
          )}
        </>
      )}
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    handleLogout: () => {
      dispatch(removeAuthHeaders());
    },
  };
}

export default connect(null, mapDispatchToProps)(withTheme(React.memo(Header)));
