/* eslint-disable max-len */
/* eslint-disable no-console */
import React from 'react';
import { connect } from 'react-redux';
import io from 'socket.io-client';
import get from 'lodash.get';
import uniqBy from 'lodash.uniqby';
import findLast from 'lodash.findlast';
import moment from 'moment';
import momenttz from 'moment-timezone';
import { withRouter } from 'react-router-dom'

import { getParams, getRole, getTabId } from '../util';
import host from '../util/host';
import router from '../util/router';

export const currentSocket = null;
class HealthCheck extends React.PureComponent {
	constructor(props) {
		super(props);
		this.healthCheckInitiated = false;
		this.uid = '';
		this.lastInteractedQuestion = {};
		this.lastSectionSubmittedAt = '';
		this.excludePages = ['timeout', 'authcode'];
		this._connectSocket = this._connectSocket.bind(this);
	}

	componentDidMount() {
		const { uid, sessionId, sessionType } = this._getIds();

		if (uid || sessionId) {
			this.uid = uid || sessionId;
			this.sessionType = sessionType;
			if (this._shouldStartSocketConnection()) {
				this._connectSocket.call(this);
				this.healthCheckInitiated = true;
				console.log(
					'HEALTH CHECK::: Initiating Health Check in Component Did Mount.',
				);
				console.log('HEALTH CHECK::: UID: ', this.uid);
			}
		}
		if (this.healthCheckInitiated) {
			this._emitUpdatePageVisits();
		}

		const route = window.location.pathname.split('/')[1];
		if (route === 'view-document') {
			this.socket.emit('PING_ROUTE_CHANGE', JSON.stringify({
				uid: this.uid,
				role: getRole(),
				route,
			}))
		}
		const pathname  = this.props?.location?.pathname;
		if (this.socket) {
			this.socket.emit('PING_ROUTE_CHANGE', JSON.stringify({
				uid: this.uid,
				role: getRole(),
				route: pathname.slice(1),
			}))
		}
	}

	componentDidUpdate(prevProps) {
		const { uid, sessionId, sessionType } = this._getIds();
		this.lastInteractedQuestion = prevProps.lastInteractedQuestion;
		if (!this.healthCheckInitiated && (uid || sessionId)) {
			this.uid = uid || sessionId;
			this.sessionType = sessionType;
			if (this._shouldStartSocketConnection()) {
				this._connectSocket.call(this);
				this.healthCheckInitiated = true;
				console.log(
					'HEALTH CHECK::: Initiating Health Check in Component Did Update.',
				);
				console.log('HEALTH CHECK::: UID: ', this.uid);
			}
		}
		if(this.props.location !== prevProps.location) {
			const pathname  = this.props?.location?.pathname;
			if (this.socket) {
				this.socket.emit('PING_ROUTE_CHANGE', JSON.stringify({
					uid: this.uid,
					role: getRole(),
					route: pathname.slice(1),
				}))
			}
		}
		if (this.lastInteractedQuestion && this.lastInteractedQuestion.question_id) {
			console.log('found new last interacted question: ', this.lastInteractedQuestion);
			if (this.socket) {
				this.socket.emit('LAST_INTERACTED_QUESTION', JSON.stringify({
					uid: this.uid,
					question: this.lastInteractedQuestion,
				}));
			}
		}
		if (this.lastSectionSubmittedAt !== prevProps.lastSectionSubmittedAt) {
			console.log('section submitted clearing questions');
			if (this.socket) {
				this.socket.emit('CLEAR_LAST_INTERACTED_QUESTIONS', JSON.stringify({
					uid: this.uid,
				}));
			}
			this.lastSectionSubmittedAt = prevProps.lastSectionSubmittedAt;
		}
	}


	_getIds = () => {
		const { location } = window;
		let uid = '';
		let sessionId = '';
		let sessionType = '';
		if (location.search.match(/uid/)) {
			uid = getParams('uid');
			sessionType = 'uid';
		}
		if (location.search.match(/session_id/)) {
			sessionId = getParams('session_id');
			sessionType = 'session_id';
		}
		return {
			uid,
			sessionId,
			sessionType,
		};
	}

	_emitUpdatePageVisits() {
		this.socket.emit(
			'UPDATE_PAGE_VISITS',
			JSON.stringify({
				uid: this.uid,
				last_visited_page: findLast(window.initialTagManager, (e) => {
					return e;
				}),
				tag_manager: uniqBy(window.initialTagManager, (e) => {
					return e.page_id;
				}),
			}),
		);
	}

	_shouldStartSocketConnection() {
		const currentPath = window.location.pathname.replace('/', '');
		if (currentPath === '') {
			return true;
		}
		return !this.excludePages.filter((pagePath) =>
			pagePath.match(currentPath),
		).length;
	}

	// eslint-disable-next-line class-methods-use-this
	_getUserData() {
		return {
			userAgent: navigator.userAgent,
			sourcePage: window.location.pathname,
			sourceUrl: window.location.search,
			userTimeZone: moment(),
			currentDateTime: momenttz.tz.guess(),
		};
	}

	_connectSocket() {
		console.log(
			'Current Socket available before creating new socket connection?',
		);

		const role = getRole();
		console.log(`role for UID is ${role}`);

		const { WS_BASE_URL } = host();
		this.socket = io.connect(WS_BASE_URL, {
			transports: ['websocket'],
			upgrade: false,
			query: {
				...this._getUserData(),
				uid: this.uid,
				sessionType: this.sessionType,
				role,
				tabId: getTabId(),
				feature: 'health',
			},
			reconnection: true,
			reconnectionAttempts: process.env.REACT_APP_SOCKET_RECONNECTION_ATTEMPT || 50,
			reconnectionDelay: 1000,
      		reconnectionDelayMax: 2000,
		});
		this.socket.on('disconnected', () => {
			this.socket.emit('DISCONNECTED', { data: '12345' });
		});
		console.log('Connecting : ', WS_BASE_URL);
		this.socket.emit(
			'START_SESSION',
			JSON.stringify({
				...this._getUserData(),
				uid: this.uid,
				sessionType: this.sessionType,
				role,
			}),
		);
		this.socket.on('reconnect', () => {
			const uid = getParams('uid');
			if (uid) {
				console.log('HEALTH CHECK::: Reconnecting : ', WS_BASE_URL);
			}
			this.socket.emit(
				'START_SESSION',
				JSON.stringify({
					...this._getUserData(),
					uid: this.uid,
					sessionType: this.sessionType,
					reconnect: true,
					role,
				}),
			);
		});
		this.socket.on('PING_EXPIRE_SESSION', (data) => {
			console.log(
				'HEALTH CHECK::: SOCKET Data received',
				JSON.stringify(data),
			);
			const uid = getParams('uid');
			const sessionId = getParams('session_id');
			if (uid === data.uid || sessionId === data.uid) {
				const currentStatus = get(
					data,
					'res.response.other_params.current_status',
					'',
				);
				const { pathname } = window.location;
				if (pathname && currentStatus) {
					let searchParam = '';
					if (uid) {
						searchParam = `?uid=${uid}`;
					} else {
						searchParam = `?session_id=${sessionId}`;
					}
					this.socket.emit(
						'PONG_EXPIRE_SESSION',
						JSON.stringify({
							uid: this.uid,
							searchParam,
							...this._getUserData(),
						}),
					);
					const { handleTimeoutResponse } = this.props;
					handleTimeoutResponse({
						data: data.res,
					});
				}
			}
		});
		console.log('Creating socket connection.');
	}

	render() {
		const { children } = this.props;
		return children;
	}
}

const mapStateToProps = (state) => {
	return {
		uid: state.uid,
		lastInteractedQuestion: state.questions.lastInteractedQuestion,
		lastSectionSubmittedAt: state.questions.lastSectionSubmittedAt,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		handleTimeoutResponse: (response) => {
			const uid = get(response, 'data.response.other_params.uid', '');
			const currentStatus = get(
				response,
				'data.response.other_params.current_status',
				'',
			).toUpperCase();
			router(dispatch, uid, false, response);
			dispatch({
				type: `GET_${currentStatus}_SUCCESS`,
				payload: get(response, 'data.response', null),
				params: {},
			});
		},
	};
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(HealthCheck),
);
