export const mockAuthQuestions = {
  data: {
    code: 200,
    status: true,
    response: {
      other_params: {
        prev_button_state: 'questions',
        next_button_text: 'Login',
        prev_button_text: 'Back',
        is_prev_btn_hidden: true,
        is_next_btn_hidden: false,
        session_id: '',
        uid: '',
        type: 'plain',
        current_status: 'login',
      },
      breadcrumbs: [
        {
          breadcrumb_id: 'LoginPage',
          section_id: '21',
          title: 'Login Page',
          state: 'active',
          source_id: 1,
          img_url:
            'https://acqcdn.s3.amazonaws.com/allstate/breadcrumb/LandingPage.svg',
          show: false,
          clickable: false,
          section_progress: {
            total_sections: 1,
            current_section_number: 0,
          },
        },
      ],
      data: {
        questionnarie: {
          page_sequence_number: 1,
          questions: {
            question_id: 'LoginPage',
            question_type: 'group',
            display_type: 'breadcrumb',
            orientation: '',
            question_text: 'Login Page',
            index: 1,
            response: '',
            sequence_number: 1,
            is_reviewable: true,
            is_editable: true,
            is_edit_icon_visible: false,
            is_hidden: false,
            presentation_type: 'hide',
            validations: [],
            properties: [],
            response_options: [],
            parent_question_answer: [],
            question_description: '',
            child_questions: false,
            question_status: '',
            question_status_message: '',
            hint_text: '',
            sureify_meta: {
              source_id: 1,
            },
            questions: [
              {
                question_id: 'LoginPageGroup',
                question_type: 'group',
                display_type: 'questions_group',
                orientation: '',
                question_text: 'Login to Continue',
                index: 1.1,
                response: '',
                sequence_number: 1,
                is_reviewable: true,
                is_editable: true,
                is_edit_icon_visible: false,
                is_hidden: false,
                presentation_type: '',
                validations: [],
                properties: '',
                response_options: [],
                parent_question_answer: [],
                question_description: '',
                child_questions: false,
                question_status: '',
                question_status_message: '',
                hint_text: '',
                sureify_meta: {
                  source_id: 1,
                },
                questions: [
                  {
                    question_id: 'userName',
                    question_type: 'text',
                    display_type: 'text',
                    orientation: 'rows',
                    question_text: 'User Name',
                    sequence_number: 1,
                    is_reviewable: true,
                    is_editable: true,
                    is_edit_icon_visible: false,
                    is_hidden: false,
                    presentation_type: '',
                    properties: [],
                    response_options: [],
                    parent_question_answer: [],
                    question_description: '',
                    question_status: '',
                    question_status_message: '',
                    hint_text: '',
                    response: '',
                    validations: {
                      required: {
                        value: true,
                        error_message: 'This is a required field.',
                      },
                    },
                    sureify_meta: {
                      source_id: 1,
                    },
                    questions: [],
                  },
                  {
                    question_id: 'password',
                    question_type: 'password',
                    display_type: 'text',
                    orientation: 'rows',
                    question_text: 'Password',
                    response: '',
                    sequence_number: 1,
                    is_reviewable: true,
                    is_editable: true,
                    is_edit_icon_visible: false,
                    is_hidden: false,
                    presentation_type: '',
                    properties: [],
                    response_options: [],
                    parent_question_answer: [],
                    question_description: '',
                    question_status: '',
                    question_status_message: '',
                    hint_text: '',
                    validations: {
                      required: {
                        value: true,
                        error_message: 'This is a required field.',
                      },
                    },
                    sureify_meta: {
                      source_id: 1,
                    },
                    questions: [],
                  },
                ],
              },
            ],
            section_progress: {
              total_sections: 1,
              current_section_number: 0,
            },
          },
          completed: false,
        },
      },
      page_desc: {
        desc: null,
        title: null,
        heading: null,
      },
    },
  },
};

export const mockNotFoundResponse = {
  data: {
    code: 200,
    status: true,
    response: {
      status: true,
      data: {
        questionnarie: {
          questions: [],
        },
      },
      other_params: {
        is_prev_btn_hidden: true,
        is_next_btn_hidden: true,
        type: 'plain',
        current_status: 'notfound',
      },
    },
  },
};

export const mockUnauthorizedResponse = {
  data: {
    code: 200,
    status: true,
    response: {
      other_params: {
        is_prev_btn_hidden: true,
        is_next_btn_hidden: true,
        type: 'plain',
        current_status: 'Unauthorize',
      },
      breadcrumbs: [
        {
          breadcrumb_id: 'Unauthorized',
          title: 'Unauthorized',
          section_progress: {
            current_section_number: 0,
            total_sections: 1,
          },
          show: false,
          state: 'active',
        },
      ],
      data: {
        questionnarie: {
          page_sequence_number: 5,
          questions: {
            question_id: 'Unauthorized',
            question_type: 'group',
            display_type: 'breadcrumb',
            orientation: '',
            question_text: '',
            page_title: '',
            index: 1,
            response: '',
            sequence_number: 1,
            is_reviewable: true,
            is_editable: true,
            is_edit_icon_visible: false,
            is_hidden: false,
            presentation_type: '',
            validations: [],
            properties: [],
            response_options: [],
            parent_question_answer: [],
            question_description: '',
            child_questions: false,
            question_status: '',
            question_status_message: '',
            hint_text: '',
            sureify_meta: {
              source_id: 1,
            },
            questions: [
              {
                question_id: 'inactivesession-group',
                question_type: 'group',
                display_type: 'questions_group',
                orientation: '',
                question_text: 'Unauthorized',
                index: 1,
                response: '',
                sequence_number: 1,
                is_reviewable: true,
                is_editable: true,
                is_edit_icon_visible: false,
                is_hidden: false,
                presentation_type: '',
                validations: [],
                properties: [],
                response_options: [],
                parent_question_answer: [],
                question_description: '',
                child_questions: false,
                question_status: '',
                question_status_message: '',
                hint_text: '',
                sureify_meta: {
                  source_id: 1,
                },
                style: {
                  left_border: {
                    width: '8px',
                    color_code: 'rgba(185, 198, 211, 1)',
                  },
                },
                questions: [
                  {
                    question_id: 'InactiveSession',
                    question_text:
                      '<p>You don’t have access to the requested application.</p>',
                    question_status: 'valid',
                    question_type: 'label',
                    display_type: 'text',
                    validations: {
                      required: {
                        value: false,
                      },
                    },
                    presentation_type: '',
                  },
                ],
              },
            ],
          },
        },
      },
      page_desc: {
        desc: null,
        title: 'Inactive Session',
        heading: null,
      },
    },
  },
};

export const mockInactiveSessionResponse = {
  data: {
    code: 200,
    status: true,
    response: {
      other_params: {
        is_prev_btn_hidden: true,
        is_next_btn_hidden: true,
        type: 'plain',
        current_status: 'inactivesession',
      },
      breadcrumbs: [
        {
          breadcrumb_id: 'inactivesession',
          title: 'inactivesession',
          section_progress: { current_section_number: 0, total_sections: 1 },
          show: false,
          state: 'active',
        },
      ],
      data: {
        questionnarie: {
          page_sequence_number: 2,
          questions: {
            question_id: 'inactivesession-breadcrumb',
            question_type: 'group',
            display_type: 'breadcrumb',
            orientation: '',
            question_text: '',
            index: 1,
            response: '',
            sequence_number: 1,
            is_reviewable: true,
            is_editable: true,
            is_edit_icon_visible: false,
            is_hidden: false,
            presentation_type: '',
            validations: [],
            properties: [],
            response_options: [],
            parent_question_answer: [],
            question_description: '',
            child_questions: false,
            question_status: '',
            question_status_message: '',
            hint_text: '',
            sureify_meta: { source_id: 1 },
            questions: [
              {
                question_id: 'inactivesession-group',
                question_type: 'group',
                display_type: 'questions_group',
                question_text:
                  '<div style=\'color: #2D2D2D;font-family: Avenir-Heavy;font-size: 26px;font-weight: normal;letter-spacing: 0px;max-width: 808px;margin: 0 auto;\'>Inactive Session</div>',
                questions: [
                  {
                    question_id: 'inactivesession-text',
                    question_text:
                      '<div style=\'background: rgb(247, 246, 244);border-radius: 2px;max-width: 808px;margin-top: 32px;padding: 24px;\'> <div style=\'color: rgb(45, 45, 45);font-family: Avenir-Heavy;font-size: 18px;font-weight: 300;line-height: 24px;max-width: 764px;margin-bottom: 16px;\'>Your session is active in a different window</div> <div style=\'color: rgb(45, 45, 45);font-family: Avenir-Medium;font-size: 16px;font-weight: normal;line-height: 24px;max-width: 760px;\'>Looks like you already have a life insurance session in progress. The session in this tab has expired and you can close out of it now. Your information will be saved in your newest session.</div><div>',
                    question_type: 'label',
                    display_type: 'text',
                  },
                ],
              },
            ],
          },
        },
      },
    },
  },
};

export const mockQuestions = [];
