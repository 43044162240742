const SET_SMALL_SCREEN = 'SET_DISPLAY_WIDTH';
const SET_LIST_RELATION = 'SET_LIST_RELATION';
const SET_DEVICE_TYPE = 'SET_DEVICE_TYPE';
const DESKTOP = 'DESKTOP';
const TABLET = 'TABLET';
const MOBILE = 'MOBILE';

const setSmallScreen = (payload) => ({
  type: SET_SMALL_SCREEN,
  payload,
});

const setListRelation = (payload) => ({
  type: SET_LIST_RELATION,
  payload,
});

const setDeviceType = (payload) => ({
  type: SET_DEVICE_TYPE,
  payload,
});

export {
  SET_SMALL_SCREEN,
  setSmallScreen,
  SET_LIST_RELATION,
  setListRelation,
  SET_DEVICE_TYPE,
  setDeviceType,
  DESKTOP,
  TABLET,
  MOBILE,
};
