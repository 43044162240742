import React from 'react';
import Cover from '../../StyledComponents/MaskCover/Index';
import { Wrapper } from './styles';

export default function PdfSuspenseFallback(){
    return(
        <>
            <Cover />
            <Wrapper>
                <h5>Loading... <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"/></h5>
            </Wrapper>
        </>
    )
}