export const GET_NEW_UID = 'GET_NEW_UID';
export const SET_UID = 'SET_UID';
export const QUOTE_API='quote_api'
export const INFO_ADDED = 'INFO_ADDED'
export const GET_INFO='get_info'
export const GET_QUOTE='get_quote'
export const ERROR='error'
export const ZIP='zip'
export const BREADCRUMBS='breadcrumbs'
export const AWAITRESPONSE='AWAITRESPONSE'
export const AUTOSUGGEST='AUTOSUGGEST'
export const DOB='DOB'
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const UPDATE_TERM_LENGTH_OPTIONS =
	'UPDATE_TERM_LENGTH_OPTIONS';
export const IS_CAMPAIGN_MOUNTED = 'is_campaign_mounted';

export const CREATE_QUOTE_START = 'CREATE_QUOTE_START';
export const CREATE_QUOTE_SUCCESS = 'CREATE_QUOTE_SUCCESS';
export const GET_DETAILS_START = 'GET_DETAILS_START';
export const GET_DETAILS_SUCCESS = 'GET_DETAILS_SUCCESS';
export const GET_QUOTE_DATA_START = 'GET_QUOTE_DATA_START';
export const GET_QUOTE_DATA_SUCCESS = 'GET_QUOTE_DATA_SUCCESS';
export const GET_QUOTEREVIEW_SUCCESS = 'GET_QUOTEREVIEW_SUCCESS';
export const GET_SIGNATURE_SUCCESS = 'GET_SIGNATURE_SUCCESS';
export const GET_PAYMENT_SUCCESS = 'GET_PAYMENT_SUCCESS';

export const SET_SELECTED = 'SET_SELECTED';
export const SET_COVERAGE_AMOUNT = 'SET_COVERAGE_AMOUNT';
export const SET_PLAN_ID = 'SET_PLAN_ID';
export const GET_NEW_QUESTION = 'GET_NEW_QUESTION';
export const API_IN_PROGRESS = 'API_IN_PROGRESS';

export const UPDATE_QUESTION_ANSWER = 'UPDATE_QUESTION_ANSWER';
export const UPDATE_DATA_SELECTOR_OPTIONS = 'UPDATE_DATA_SELECTOR_OPTIONS';
export const API_FAILED = 'API_FAILED';

export const GET_NEW_QUESTION_START = 'GET_NEW_QUESTION_START';
export const GET_QUESTIONS_SUCCESS = 'GET_QUESTIONS_SUCCESS';
export const GET_QUESTION_FAILURE = 'GET_QUESTION_FAILURE';

export const SET_CURRENT_BREADCRUMB = 'SET_CURRENT_BREADCRUMB';
export const SET_DISPLAY_AS_MOBILE = 'SET_DISPLAY_AS_MOBILE'

export const GET_REVIEW_SUCCESS = 'GET_REVIEW_SUCCESS'
export const GET_REQUOTE_SUCCESS = 'GET_REQUOTE_SUCCESS'
export const GET_QUOTE_SUCCESS = 'GET_QUOTE_SUCCESS';
export const GET_DOCUMENT_SUCCESS = 'GET_DOCUMENT_SUCCESS'
export const GET_PLAN_SUCCESS = 'GET_PLAN_SUCCESS';
export const GET_TIMEOUT_SUCCESS = 'GET_TIMEOUT_SUCCESS';
export const GET_CHALLENGE_SUCCESS = 'GET_CHALLENGE_SUCCESS';
export const GET_RIDERS_SUCCESS = 'GET_RIDERS_SUCCESS';
export const GET_COMPLETED_SUCCESS = 'GET_COMPLETED_SUCCESS';
export const GET_LOGIN_SUCCESS = 'GET_LOGIN_SUCCESS';
export const GET_KNOCKOUT_SUCCESS = 'GET_KNOCKOUT_SUCCESS';
export const GET_NOQUOTE_SUCCESS = 'GET_NOQUOTE_SUCCESS';
export const GET_NONCLEAN_SUCCESS = 'GET_NONCLEAN_SUCCESS';
export const GET_UNAUTHORIZE_SUCCESS = 'GET_UNAUTHORIZE_SUCCESS';
export const GET_INACTIVESESSION_SUCCESS = 'GET_INACTIVESESSION_SUCCESS';
export const GET_NOTFOUND_SUCCESS = 'GET_NOTFOUND_SUCCESS';


export const SHOW_ERROR_MESSAGE = 'SHOW_ERROR_MESSAGE';
export const HIDE_ERROR_MESSAGE = 'HIDE_ERROR_MESSAGE';

export const SET_ACTION_SUCCESS_MESSAGE = 'SET_ACTION_SUCCESS_MESSAGE';
export const SET_ACTION_FAILURE_MESSAGE = 'SET_ACTION_FAILURE_MESSAGE';

export const SET_BREADCRUMB_HEADING = 'SET_BREADCRUMB_HEADING';
export const TOGGLE_ESIGN_BUTTON = 'TOGGLE_ESIGN_BUTTON';
export const UPDATE_LIST_SECTION = 'UPDATE_LIST_SECTION';

export const APP_CONFIG = 'APP_CONFIG'

export const PAGE_ACTIVITY_LOGGER = 'PAGE_ACTIVITY_LOGGER';
export const SET_DESTINATION_URL = 'SET_DESTINATION_URL';
export const SET_LOGIN_CONFIG = 'SET_LOGIN_CONFIG';
export const UPDATE_LAST_FOCUSED_ELEMENT_ID = 'UPDATE_LAST_FOCUSED_ELEMENT_ID';
export const UPDATE_QUESTION_ANSWER_TIME_STAMP = 'UPDATE_QUESTION_ANSWER_TIME_STAMP';


export const SET_POPUP_DETAILS = 'SET_POPUP_DETAILS';
