/* eslint-disable no-continue */
import get from 'lodash.get';
import cloneDeep from 'lodash.clonedeep';
import { isPlainObject } from '../../utils';

const isEmpty = require('lodash.isempty');

const HELPER = {
	LABEL_QUESTION_DISPLAY_TYPES: [
		'list',
		'questions_group',
		'breadcrumb',
	],
	getRandomId: () => {
		return Math.random().toString(36).substring(7);
	},
	extractAnswer: (answer, currentAnswer = []) => {
		if (typeof answer !== 'object') {
			return answer;
		}
		if (isPlainObject(answer) && answer.type === 'array') {
			if (answer.checked) {
				return [...currentAnswer, answer.id];
			}
			return currentAnswer.filter((a) => a !== answer.id);
		}
		if (typeof answer === 'object') {
			return answer;
		}
		return null;
	},
	/**
	 *
	 */
	updateResetAnswer: (obj, currentAnswer) => {
		const { reset, answer } = obj;
		const isReset = reset.filter(item => item.id === answer.id)[0];
		if (answer.checked) {
			if (isReset) {
				return [answer.id];
			}
			if (currentAnswer.length) {
				return [
					...currentAnswer.filter(a => {
						return reset.filter(item => item.id !== a).length;
					}),
					answer.id,
				];
			}
			return [
				answer.id,
			]
		}
		return currentAnswer.filter(a => a !== answer.id);
	},

	getTimeStamps: (previousTime, timeObj) => {
		if(Object.keys(timeObj).length) {
			const time = previousTime ? [...previousTime, timeObj] : [timeObj]
			return time;
		} 
		return [];
	},

	addOrReplace: (list = [], obj, key) => {
		let matchFound = false;
		let newList = [];
		newList = list.map((item) => {
			if (item.question_id === obj.question_id) {
				matchFound = true;
				let resp = {
					...item,
					question_id: obj.question_id,
					hasEdit: obj.hasEdit,
					hasMask: obj.hasMask,
					[key]: obj.reset ? HELPER.updateResetAnswer(obj, item.answer) : HELPER.extractAnswer(obj[key], item.answer),
				}
				if(!isEmpty(obj.transactionLog) && key === 'transaction_log') {
					const timeStampData = obj.transactionLog;
					const numTimesAnswered = timeStampData.length; 
					resp = {
						...resp,
						transaction_log: timeStampData,
						num_times_answered: numTimesAnswered,
						last_submitted_at: obj.lastSubmittedAt,
					}
				} else if(key !== 'transaction_log') {
					resp = {
						...resp,
						[key]: obj.reset ? HELPER.updateResetAnswer(obj, item.answer) : HELPER.extractAnswer(obj[key], item.answer),
					}
				}
				if (obj.parentId) {
					resp = {
						...resp,
						parentId: obj.parentId,
					}
				}
				if (obj.immediateParent) {
					resp = {
						...resp,
						immediateParent: obj.immediateParent,
					}
				}
				// Parent List Question Id - N List
				if (obj.parentListQuestionId) {
					resp = {
						...resp,
						parentListQuestionId: obj.parentListQuestionId,
					}
				}
				// Parent List Question Id - Normal List
				if (obj.listParentId) {
					resp = {
						...resp,
						listParentId: obj.listParentId,
					}
				}
				// Normal List Index
				if (Number.isInteger(obj.listIndex) && obj.listIndex >= 0) {
					resp = {
						...resp,
						listIndex: obj.listIndex,
					}
				}
				return resp; 
			}
			return item;
		});
		if (!matchFound) {
			const isArrayType = get(obj, 'answer.type', '') === 'array';
			if (obj.question_id && isArrayType) {
				const _answer = get(obj, 'answer.id', '');
				newList.push({ ...obj, answer: [_answer] });
			} else {
				newList.push(obj);
			}
		}
		return newList;
	},

  /**
   * 
   */
  getRequiredSchema: (list) => {
    return list.reduce((acc, question) => {
      if (HELPER.LABEL_QUESTION_DISPLAY_TYPES.indexOf(question.display_type) > -1) {
        return acc;
      }
      if (get(question, 'validations.required.value', false) && !get(question, 'is_hidden', false)) {
        acc.push(question.question_id);
      }
	  if(question && question.validations && !question.validations.required && question.question_status_message && question.question_status === 'invalid'){
		acc.push(question.question_id);  
	  }
      return acc;
    }, []);
  },

  /**
   * 
   */
  isDisplayTypeQuestion: (question) => {
    return question.question_type !== 'group';
  },

  /**
   * 
   */
  parseArray: (list, rex = 0)=> {
	  const parentList = list;
	  for (let index = 0; index < parentList.length; index+=1) {
		const question = parentList[index];
		if (question.display_type !== 'image_text') {
			if (question.submission_type === 'all') continue;
			if (HELPER.isDisplayTypeQuestion(question) && question.questions && question.questions.length) {
				if (question.child_questions) {
					const rIndex = question.reflexive_index === undefined ? 0 : question.reflexive_index
					parentList.splice(index + 1, 0, ...question.questions.map(q => ({ ...q, reflexive_index: rIndex + 1, question_id: q.question_id.toString() })))
				}else if(question.question_type === 'modal') {
					parentList.splice(index + 1, 0, ...question.questions.map(q => ({ ...q, isModalQuestion: true , question_id: q.question_id.toString() })));
				}  else {
				  parentList.splice(index + 1, 0, ...question.questions.map(q => ({ ...q, question_id: q.question_id.toString() })));
				}
				question.questions.length = 0;
			}
			if (question.questions && question.questions.length) {
				HELPER.parseArray(question.questions, question.reflexive_index || rex);
			}
			question.reflexive_index = question.reflexive_index || rex;
		}
    }
  },

  /**
   * @description function to extract and insert reflexive questions in the question list.
   * @param {Object} questionObject - the parent (group) question after the breadcrumb.
   */
  insertReflexiveQuestion: (questionObject) => {
	const questionList = [cloneDeep(questionObject)];
    HELPER.parseArray(questionList)
    const temp = []
    HELPER.flattenQuestions(questionList, temp, 0);
    return temp
  },

	/**
	 * @description funtion to flatten the questions.
	 * @param questions question list - array
	 * @param newList array
	 * @param shouldFlatListQ boolean
	 */
	flattenQuestions: (questions, newList, shouldFlatListQ = false) => {
		if (questions && questions.length) {
			questions.forEach((question) => {
				newList.push({
					...question,
				});
				if (question.questions && question.submission_type !== 'all' && (shouldFlatListQ || question.display_type !== 'list') && get(question, 'properties.appearance.value', '') !== 'accordion' && question.display_type !== 'image_text') {
					HELPER.flattenQuestions(question.questions, newList, shouldFlatListQ);
				}
			});
		}
	},
	/**
	 * @description functione extracts the current breadcrumb name and transforms it
	 * to a keyword.
	 * @param {Object} data received from the backend
	 * @returns {String}
	 */
	getCurrentBreadcrumb: (breadcrumbs, id) => {
		const breadcrumb = breadcrumbs.filter((item) => {
			if (id && item.state === 'active') {
				return item.title.replace(/ /g, '').toLowerCase() === id;
			}
			return item.state === 'active';
		})[0];
		if (breadcrumb) {
			return {
				id:
					breadcrumb.breadcrumb_id.toLowerCase() ||
					breadcrumb.title.replace(/ /g, '').toLowerCase(),
				index: breadcrumbs.findIndex(
					(obj) => obj.breadcrumb_id === breadcrumb.breadcrumb_id,
				),
				active: true,
				...breadcrumb,
			};
		}
		return null;
	},

  /**
   * 
   */
  shouldEnableContinue: (requiredSchema, allAnswers, allErrors = [], isList=false, listDisplay=false, listError=false, eSign) => {
	let enable = true;
    if (allErrors.length !== 0) {
      return false;
	}
	
	if(listError){
	  return false
	}

	for (let index = 0; index < requiredSchema.length; index += 1) {
		const answeredQuestion = allAnswers.filter(
			(item) =>
				item.question_id === requiredSchema[index] && (item.answer || item.answer === 0),
		);
		if (!answeredQuestion.length) {
			enable = false;
			break;
		}
		
		const { answer } = answeredQuestion[0];
		if(Array.isArray(answer) && !answer.length){
			enable = false;
			break;
		}
	}

    if(isList && !listDisplay){
      enable = false
    }
	
	if(eSign) {
		const { hasESign, eSignEnable } = eSign;
		if (hasESign) {
			enable = enable && eSignEnable;
		}
	}
	
    return enable;
  },

  isValidAnswer: (answer) => {
		if (typeof answer === 'string' || typeof answer === 'number') {
			return !!answer;
		}
		if (Array.isArray(answer) && answer.length) {
			return true;
		}
		if (isPlainObject(answer) && answer.id) {
			return true;
		}
		return false;
	},

    findAndGetTimeStamp: (
		questionList,
		questionId,
	) => {
        const question = questionList.find(q => q.question_id === questionId);
        return {
            question_id: question?.question_id ?? '',
            num_times_answered: question?.num_times_answered ?? 0,
            transaction_log: question?.transaction_log ?? [],
            last_submitted_at: question?.last_submitted_at ?? '',
        }
	},

	findAndUpdateTimeStamp: (
		questionList,
		questionId,
		transactionLog,
		lastSubmittedAt,
	) => {
		return questionList.map((question) => {
			if (question.question_id === questionId) {
				const numTimesAnswered = get(question, 'num_times_answered', undefined)
				return {
					...question,
					transaction_log: transactionLog,
					num_times_answered: numTimesAnswered ? (numTimesAnswered + 1) : 1,
					last_submitted_at: lastSubmittedAt,
				}
			}
			return question;
		});
	},

	findAndUpdateResponse: (
		questionList,
		questionId,
		response,
		questionResponse,
		isControlledInput = false,
	) => {
		return questionList.map((question) => {
			if (question.question_id === questionId) {
				return {
					...question,
					isControlledInput,
					response: HELPER.isValidAnswer(questionResponse)
						? questionResponse
						: HELPER.extractAnswer(response, question.response),
				};
			}
			return question;
		});
	},

	/**
	 * @description funtion to return masked responses from questionsList
	 * @param questionsList questions list - array
	 * @param answersArray All answers - array
	 */
	getMaskedResponses: (questionsList, answersArray) => {
		const maskedQArray = [];
		const maskedQIds = answersArray.filter(q => q.question_id && q.hasMask).map(a => a.question_id);
		questionsList.forEach(q => {
			const hasMask = maskedQIds.includes(q.question_id)
			if (hasMask) {
			const response = get(q, 'original_response', q.response);
				maskedQArray.push({
					question_id: q.question_id,
					answer: response,
				})
			}
		});
		return maskedQArray;
	},

    // sort 2 JSON arrays by prop value
	getSortOrder(prop) {    
		return (a, b) => {    
			if (a[prop] > b[prop]) {    
				return 1;    
			} if (a[prop] < b[prop]) {    
				return -1;    
			}    
			return 0;    
		}    
	},  
	
	// returns the answers array of matching id
	getAnswersArray(answersArray, questionId ){
	   return answersArray.filter(question => question.question_id === questionId);
	},

  removeDuplicate: (sectionAllQuestions, currentQuestions) => {
    // check for two layout property for question
	const layout = get(currentQuestions, 'properties.layout', undefined);
	let currentQuestionId;
	if(!currentQuestions.questions) {
		currentQuestionId = currentQuestions.questionId;
	} else {
		currentQuestionId = layout ? currentQuestions.questions[1].question_id :
		currentQuestions.questions[0].question_id; // get the questions_groups question id
	}
    return sectionAllQuestions.reduce((acc, questions) => {
      // check index 2 for first question in the list and for index 1 fro two-column question
      if (questions.questionList[1].question_id === currentQuestionId ||
         (layout && questions.questionList[0].question_id === currentQuestionId)) {
        return acc;
      }
      acc.push({
        ...questions,
        completed: true,
      });
      return acc;
    }, [])
  },

  replaceSectionQuestion: (oldQuestions, newQuestionsList, index) => {
    return oldQuestions.map((quesObj, i) => {
      if (i === index) {
       const requiredSchema = HELPER.getRequiredSchema(newQuestionsList)
        return {
          ...quesObj,
          questionList: newQuestionsList,
          requiredSchema,
          enableContinue: HELPER.shouldEnableContinue(requiredSchema, quesObj.answersArray),
        }
      }
      return quesObj;
    })
  },

  	buildAnswersArrayReduceFunc: (Q, arr) => {
		return Q.reduce((acc, q) => {
			if (
				q.display_type === 'list' ||
				q.display_type === 'questions_group' ||
				q.display_type === 'breadcrumb'
			) {
				return acc;
			}
			if (q.question_id) {
				let { response } = q;
				if (isPlainObject(response)) {
					response = response.id;
				}
				if (Array.isArray(response)) {
					response = response.map((r) => r.id);
				}
				// Checks if question contain mask validation.
				const hasMask = !!get(q, 'validations.mask.mask_char', false);
				acc.push({
					question_id: q.question_id,
					answer: response,
					hasEdit: false,
					hasMask,
				});
			}
			return acc;
		}, arr);
	},

	buildAnswersArray: (questionList, originalQ = []) => {
		const answersArray = []
		HELPER.buildAnswersArrayReduceFunc(questionList, answersArray);
		HELPER.buildAnswersArrayReduceFunc(originalQ, answersArray);
		return answersArray
	},

  	getOriginalQuestions: (questionObj) => {
		const listQuestion =
			get(questionObj, 'questions[0].display_type') === 'list'
				? questionObj.questions[0]
				: questionObj.questions.find((q) => q.display_type === 'list');

		if (listQuestion && listQuestion.display_type === 'list') {
			// Adds reflexive questions support for list questions
			// Related Task - https://sureify.atlassian.net/browse/AS-323
			const reflexiveListQ = []
			HELPER.flattenQuestions(listQuestion.original_questions || listQuestion.questions, reflexiveListQ, true)
			return reflexiveListQ
		}
		return [];
	},
	
	getAnsweredLisQuestionIds: (questionObj) => {
		return questionObj
		.filter(question => question.display_type === 'list' && question.child_questions_completed_flag)
		.map(q => q.question_id)
	},

	isQuestionGroupHidden: (questionList = []) => {
		if (questionList && questionList.length) {
			const [parentQuestion, ...childQuestions] = questionList;
			if (parentQuestion) {
				return parentQuestion.is_hidden || childQuestions.every(question => question.is_hidden);
			}
		}
		return false;
	},

	getOperatingSystem: (window) => {
		let operatingSystem = 'Not known';
		if (window.navigator.appVersion.indexOf('Win') !== -1) { operatingSystem = 'Windows OS'; }
		if (window.navigator.appVersion.indexOf('Mac') !== -1) { operatingSystem = 'MacOS'; }
		if (window.navigator.appVersion.indexOf('X11') !== -1) { operatingSystem = 'UNIX OS'; }
		if (window.navigator.appVersion.indexOf('Linux') !== -1) { operatingSystem = 'Linux OS'; }
	  
		return operatingSystem;
	},

	getBrowser: (userAgent) => {
		let browserName = ''
		if(userAgent.match(/edg/i)){
			browserName = 'Edge';
		}else if(userAgent.match(/opr\//i)){
			browserName = 'Opera';
		}else if(userAgent.match(/chrome|chromium|crios/i)){
			browserName = 'Google Chrome';
		}else if(userAgent.match(/firefox|fxios/i)){
			browserName = 'Mozilla Firefox';
		}  else if(userAgent.match(/safari/i)){
			browserName = 'Safari';
		}else if(userAgent.match(/trident/i)){
			browserName = 'MS IE';
		}else{
			browserName='Other';
		}
		return browserName;
	},

	getDeviceType: () => {
		const ua = navigator.userAgent
		let deviceType = '';
		if(/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)){
			deviceType = 'Tablet'
			if(/ipad/i.test(ua))
				deviceType = 'Tablet - iPad'
			
			return deviceType;
		}
		if(/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)){
			deviceType = 'Mobile';
			if(/iP(hone|od)/i.test(ua))
				deviceType = 'Mobile - iPhone'
			return deviceType;
		}
		deviceType = 'Desktop';
		return deviceType;
	},
}

export const { getCurrentBreadcrumb } = HELPER;

export default HELPER;
