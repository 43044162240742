import React, { useCallback } from 'react';
import styled, { withTheme } from 'styled-components';
import { connect } from 'react-redux';
import get from 'lodash.get'
import { API_IN_PROGRESS, SET_ACTION_FAILURE_MESSAGE } from '../../redux/types';
import { axiosInstance } from '../../util/axios';
import router from '../../util/router';
import { doFormotivAction } from '../../utils';

const NeedHelpBtn = styled.button`
    margin-right: 20px;
    background: ${({ btnTheme }) => btnTheme.selectedBgColor ? btnTheme.selectedBgColor : '#ccc'};
    color: ${({ btnTheme }) => btnTheme.bgColor ? btnTheme.bgColor : '#000'};;
    border-radius: 4px;
    font-family: 'Avenir-Medium';
    font-display: swap;
    font-size: 16px;
    font-weight: 700;
    padding: 10px 20px;
    :focus,
    :hover{
        text-decoration: underline;
    }
`

const NeedHelpButton = ({
    buttonText,
    _updateLoading,
    _updateQuestionsResponse,
    _handleActionMessage,
    btnTheme,
}) => {

    const needHelpClickHandler = useCallback(() => {
        const queryParams = {
            partb_need_help: 1,
        }
        const submitSubListData = async () => {
            _updateLoading(true);
            axiosInstance
              .post('/questions', queryParams)
              .then((_response) => {
                _updateLoading(false);
                const currentStatus = get(
                  _response,
                  'data.response.other_params.current_status',
                  '',
                ).toUpperCase();

                if (currentStatus)
                  _updateQuestionsResponse(currentStatus, _response, { isReflexive: true });
              })
              .catch((error) => {
                _updateLoading(false);
                console.error(error);
                if (error.response) {
                    const errorMessage = get(error.response, 'data.errors.message', 
						'Some Error Occured!');
                    _handleActionMessage(errorMessage);
				}
              });
        }
        submitSubListData();
    })
    return (
      <>
        <NeedHelpBtn
          type="button"
          onClick={ () => {
              needHelpClickHandler()
              doFormotivAction('inputActivity', false, { name: 'Request Help', type: 'button', value: 'Request Help Trigerred' })
            } }
          btnTheme={ btnTheme }
        >
          {buttonText}
        </NeedHelpBtn>
      </>
    );
}

const mapDispatchToProps = (dispatch, props) => {
    const { uid } = props
    return {
        _updateLoading     : (data) => { dispatch({ type: API_IN_PROGRESS, payload: { isLoading: data } })},
        _updateQuestionsResponse: (currentStatus, response, params) => {
            router(dispatch, uid, params.isReflexive, response);
            dispatch({
                type: `GET_${currentStatus}_SUCCESS`,
                payload: get(response, 'data.response', null),
                params,
            });
        },
        _handleActionMessage: (errorMessage) => {
            dispatch({
                type: SET_ACTION_FAILURE_MESSAGE,
                payload: {
                    actionMessage: {
                        type: 'error',
                        message: errorMessage,
                    },
                },
            });
        },
    }
}

export default withTheme(connect(null, mapDispatchToProps)(React.memo(NeedHelpButton)))