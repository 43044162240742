/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable no-console */
import React, { Suspense, lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import ScrollToTop from './ScrollToTop';
// import Loader from './cruxComponents/Loader';

import GetNewUID from './Pages/GetNewUID';
import { history } from './redux/configureStore';

// const GetNewUID = lazy(() => import('./Pages/GetNewUID'));
const NewQuestions = lazy(() => import('./Pages/Questions/NewQuestions'));
const PageNotFound = lazy(() => import('./Pages/PageNotFound'));
const PDFDownloadLink = lazy(() => import('./Pages/PDFDownloadLink'));
const Loader = lazy(() => import('./cruxComponents/Loader'));

const newQsPaths = [
	'/quote',
	'/plan',
	'/timeout',
	'/quotereview',
	'/signature',
	'/questions/:id',
	'/review',
	'/payment',
	'/challenge',
	'/riders',
	'/completed',
	'/knockout',
	'/noquote',
	'/nonclean',
	'/thankyou',
	'/inactivesession',
]

const loaderPaths = [
	'/onlinequote',
	'/employee',
	'/referralquote',
	'/enterprise',
	'/emailquote',
	'/expressquote',
	'/searchquote',
]

const quoteDataPgPaths = [
	'/login',
]

const pdfDownloadPaths = [
	'/view-document',
]

const getNewUIDPaths = [
	'/',
	'/init',
]

const routingPaths = {
	newQsPaths,
	loaderPaths,
	quoteDataPgPaths,
	pdfDownloadPaths,
	getNewUIDPaths,
}

const isPlanBasedRoute = process.env.REACT_APP_IS_PLAN_BASED_ROUTE === 'true';
const lifePlan = process.env.REACT_APP_LIFE_PLAN;

console.log('App Routing logs', process.env.REACT_APP_IS_PLAN_BASED_ROUTE, isPlanBasedRoute, process.env.REACT_APP_LIFE_PLAN, lifePlan);

if(isPlanBasedRoute){
	for(const eachPath in routingPaths){
		routingPaths[eachPath] = routingPaths[eachPath].map((p) => {
			return `/${lifePlan}/${p}`
		})
	}
}

const AppRouter = () => {
	return (
		<>
			<ConnectedRouter history={ history } noInitialPop>
				<ScrollToTop>
					<Suspense fallback={ <><GetNewUID /></> }>
						<Switch>
							<Route
								path={ routingPaths.newQsPaths }
								component={ NewQuestions }
							/>
							<Route
								path={ routingPaths.loaderPaths }
								component={ Loader }
							/>
							<Route
								path={ routingPaths.quoteDataPgPaths }
								component={ Loader }
							/>
							<Route
								path={ routingPaths.pdfDownloadPaths }
								component={ PDFDownloadLink }
							/>
							<Route
								exact
								path={ routingPaths.getNewUIDPaths }
								component={ Loader }
							/>
							<Route
								exact
								path="*"
								component={ PageNotFound }
							/>
						</Switch>
					</Suspense>
				</ScrollToTop>
			</ConnectedRouter>
		</>
	)
};

export default AppRouter;
