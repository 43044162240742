/* eslint-disable react/forbid-prop-types */
import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider, createGlobalStyle }  from 'styled-components';
import { Provider } from 'react-redux';
import { withRouter } from 'react-router-dom'
import get from 'lodash.get';
import Layout from './layout';
import configureStore, { history } from './redux/configureStore';
import initApp from './initApp';
import { resizeListener } from './utils';
import HealthCheck from './healthCheck';
import IdentityCheck from './identityCheck';
import { getMockQuestionSet, getNextQuestionSet } from './redux/actions';
import { getParams } from './util';
import { mockInactiveSessionResponse } from './mockResponse';
import useDocumentTitle from './hooks/useDocumentTitle';
import SessionStorageServices from './util/sessionStorageServices';


const store = configureStore();

const GlobalStyle = createGlobalStyle`
  font-family: ${({ fontFamily }) => fontFamily};
  // Color contrast configs for SelectOption
  @media (forced-colors: active) {
	body {
		div[class$="menu"] > div > .app-select-option {
			background-color: #000;
			color: WindowText;
			forced-color-adjust: none;
			&[data-selected="true"] {
				background-color: WindowText;
				color: Highlight;
				&:hover {
					color: ButtonText;
				}
			}
			&:hover {
				background-color: Highlight;
				color: WindowText;
			}
		}
	}
  }
`

const Main = ({ config }) => {

	const resizeHandler = useCallback(resizeListener.bind(window, store), []);

	const handleBrowserBackPress = () => {
		const uid = getParams('uid');
		const role = getParams('role') 
		const params = { uid };
		if(role) {
			params.role = role;
		}
		store.dispatch(getNextQuestionSet('/questions', params))
	}

	const _handleBrowserBackPress = useCallback(handleBrowserBackPress.bind(window), []);

	useEffect(() => {
		initApp(store, config);
		window.addEventListener('resize', resizeHandler);
		window.addEventListener('popstate', _handleBrowserBackPress)
		return () => {
			window.removeEventListener('resize', resizeHandler);
			window.removeEventListener('popstate', _handleBrowserBackPress);
		}
	}, []);

	const _getMockQuestionSet = useCallback(getMockQuestionSet(mockInactiveSessionResponse), [mockInactiveSessionResponse]);
	const _getNextQuestionSet = useCallback(getNextQuestionSet, []);
	const _getParams = useCallback(getParams, []);


	useEffect(() => {
		return history.listen(() => {
			const uid = _getParams('uid');
			const historyActions = ['PUSH', 'POP', 'REPLACE'];
			const identity = SessionStorageServices.getIdentity(uid);
			if (historyActions.indexOf(history.action) > -1) {
				if (identity === 'invalid') {
					store.dispatch(_getMockQuestionSet());
					return;
				} 
			}
		  	if (history.action === 'POP') {
			  if(uid === null) {
				  // this covers the case when user clicks on browser back button 
				  // at the landing page.
				  // https://sureify.atlassian.net/browse/NAT-1573
				window.history.back()
			  } else {
				store.dispatch(
					_getNextQuestionSet('/questions', {
						uid,
					}, {
						reload: true,
					}))
			  }
			}
		})
	  }, [history])

	const documentTitle = get(config, 'global.appTitle', 'Acquire')
	useDocumentTitle(documentTitle)
	
	return (
		<Provider store={ store }>
			{/* <Helmet>
				<title>
					{get(config, 'global.appTitle', 'Acquire')}
				</title>
			</Helmet> */}
			<HealthCheck>
				<ThemeProvider theme={ { config } }>
					<IdentityCheck>
						<GlobalStyle fontFamily={ get(config, 'theme.global.fontFamily', 'Proxima Nova') } />
						<Layout />
					</IdentityCheck>
				</ThemeProvider>
			</HealthCheck>
		</Provider>
	);
};

Main.propTypes = {
	config: PropTypes.any.isRequired,
};
export default withRouter(Main);
