import { SET_DESTINATION_URL, SET_LOGIN_CONFIG } from '../types';

const isPlanBasedRoute = process.env.REACT_APP_IS_PLAN_BASED_ROUTE === 'true';
const lifePlan = process.env.REACT_APP_LIFE_PLAN;

const initialState = {
  login: {},
  destinationURL: isPlanBasedRoute ? `/${lifePlan}` : '/',
}

const auth = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_DESTINATION_URL:
      return {
        ...state,
        destinationURL: payload,
      };
    case SET_LOGIN_CONFIG:
      return {
        ...state,
        login: payload,
      }
    default:
      return state;
  }
}

export default auth;
