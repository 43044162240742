/* eslint-disable import/no-cycle */
import Axios from 'axios';
import get from 'lodash.get';
import { osName, osVersion, fullBrowserVersion, browserName } from 'react-device-detect';
import { convertQueryStringToObject } from '.';
import LocalStorageServices from './localStorageServices';
import SessionStorageServices from './sessionStorageServices';
import host from './host';
import config from '../config';

const { REACT_APP_BASE_URL } = host();
const OIDC_AUTH_API = get(config, 'features.login.OIDCAuthURL');

export const axiosInstance = Axios.create({
  baseURL: REACT_APP_BASE_URL,
  method: 'post',
  headers: {
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    Pragma: 'no-cache',
    Expires: '0',
    Accept: 'application/json',
    Browser: `${browserName} (${fullBrowserVersion})`,
    OS: `${osName} (${osVersion})`,
  },
});

export const axiosgetInstance = Axios.create({
  baseURL: REACT_APP_BASE_URL,
  method: 'get',
  headers: {
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    Pragma: 'no-cache',
    Expires: '0',
    Accept: 'application/json',
    Browser: `${browserName} (${fullBrowserVersion})`,
    OS: `${osName} (${osVersion})`,
  },
});

axiosgetInstance.interceptors.request.use(
  request => {
    if (request) {
      const searchParams = window.location.search.replace('?', '');
      const queryObject = convertQueryStringToObject(searchParams);
      if (queryObject && queryObject.role) {
        request.headers = { ...request.headers, 'X-User-Type' : queryObject.role };
      }
    }
    return request;
  },
  err => Promise.reject(err),
);

axiosInstance.interceptors.request.use(
  request => {
    if (request && request.data) {
      const searchParams = window.location.search.replace('?', '');
      const queryObject = convertQueryStringToObject(searchParams);
      const accessToken = LocalStorageServices.getAccessToken();

      const navigationUrlObj = JSON.parse(window.sessionStorage.getItem('navigationUrlObj'));
      const role = get(navigationUrlObj, 'role');
      const originalURL = get(navigationUrlObj, 'url');

      if (request.url === '/questions' && Object.keys(request.data).includes('oidc_auth_fail')) {
        if (originalURL) {
          const url = new URL(originalURL);
          const uid = url.searchParams.get('uid');
          if (uid) request.data.uid = uid;
        }
        if (role) request.data.role = role;
      } else {
        Object.keys(queryObject).forEach(key => {
          try {
            if (!(key in request.data)) {
              request.data[key] = queryObject[key];
            }
          } catch (e) {
            console.error(e);
          }
        });
      }
      if (accessToken) {
        request.headers.Authorization = `Bearer ${accessToken}`;
      }
      if (request.url && request.url.includes(OIDC_AUTH_API)) {
        request.headers = { ...request.headers, 'X-User-Type' : role };
      }
    }
    return request;
  },
  err => Promise.reject(err),
);

/**
 *  @description Axios Interceptor to fetch new access token via 
 * refresh token (if the response code comes as unauthorized 401).
 */ 
axiosInstance.interceptors.response.use(
  (response) => {
    const originalRequest = response.config;
    const uid = get(response, 'data.response.other_params.uid', '');

    // Sets identity based on if session is valid or not for that uid.
    const identity = SessionStorageServices.getIdentity(uid);
    originalRequest._identity = identity === 'invalid' ? 'invalid' : 'valid';

    return response;
  }, (error) => {
    const originalRequest = error.config;

    // If the refresh token is not valid
    if (error.response && 
      (error.response.status === 400 || error.response.status === 401) && 
      originalRequest.url.includes('/auth/refresh_token')) 
    {
      console.error(error);
      LocalStorageServices.clearToken();
    }

    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
    }
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  response => {
    if (response.data && response.data.response) {
      const { tag_manager: tagManager } = get(
        response,
        'data.tag_manager.pages',
        [],
      );
      const { tag_manager_fields: tagManagerFields } = response.data;

      if (tagManager && tagManager.length > 0) {
        window.initialTagManager = tagManager;
      } else {
        window.initialTagManager = window.initialTagManager || [];
      }

      window.uniqueTransactionId =
        response.data.transactionId ||
        (response.data.tag_manager &&
          response.data.tag_manager.transaction_id) ||
        window.uniqueTransactionId;
      if (tagManagerFields) {
        if (
          tagManagerFields.gtmQuestionsList &&
          tagManagerFields.gtmQuestionsList.length > 0
        ) {
          window.gtmQuestionsList = tagManagerFields.gtmQuestionsList;
        } else {
          window.gtmQuestionsList = window.gtmQuestionsList || [];
        }

        if (
          tagManagerFields.virtualPageVisitsQuestionsList &&
          tagManagerFields.virtualPageVisitsQuestionsList.length > 0
        ) {
          window.virtualPageVisitsQuestionsList =
            tagManagerFields.virtualPageVisitsQuestionsList;
        } else {
          window.virtualPageVisitsQuestionsList =
            window.virtualPageVisitsQuestionsList || [];
        }

        if (
          tagManagerFields.virtualPageVisitsQuestionsWithAnsConsidered &&
          tagManagerFields.virtualPageVisitsQuestionsWithAnsConsidered.length >
            0
        ) {
          window.virtualPageVisitsQuestionsWithAnsConsidered =
            tagManagerFields.virtualPageVisitsQuestionsWithAnsConsidered;
        } else {
          window.virtualPageVisitsQuestionsWithAnsConsidered =
            window.virtualPageVisitsQuestionsWithAnsConsidered || [];
        }
      }
    } else {
      window.initialTagManager = window.initialTagManager || [];
      window.gtmQuestionsList = window.gtmQuestionsList || [];
      window.virtualPageVisitsQuestionsList =
        window.virtualPageVisitsQuestionsList || [];
      window.virtualPageVisitsQuestionsWithAnsConsidered =
        window.virtualPageVisitsQuestionsWithAnsConsidered || [];
    }
    return response;
  },
  err => Promise.reject(err),
);

export const TEST = 'THIS IS A FAKE EXPORT, NEED TO REFACTOR BEFORE MAKING axiosInstance DEFAULT EXPORT';
