import get from 'lodash.get'

export const isListQuestion = (questionList, isReview) => {
  if (isReview) {
    return false;
  }
  const question0 = questionList[0]; // 0th index is breadcrumb question.
  const question1 = questionList[1]; // 1st index will have display type

  if (question1 && question1.display_type === 'list') {
    return true;
  }

  if (questionList.find(q => q.display_type === 'list')) {
    return true;
  }

  if(question0.question_id) {
    return !!(question0.question_id.match(/beneficiary/i));
  }
  return false;
}

export const getListDisplayMode = (questionList) => {
  const listQuestion = questionList.filter(question => question.display_type === 'list')[0];
  if (listQuestion) {
    return listQuestion.child_questions_completed_flag;
  }
  return false;
}

export const hasListItems = (questionList) => {
  const listQuestion = questionList[1];
  // check is list is array or not
  const list = get(listQuestion,'questions',[])
  if(Array.isArray(list) && list.length) {
    return true;
  }
  return false
}

export const getAddButtonText = (questionList) => {
  const listQuestion = questionList.filter(question => question.display_type === 'list')[0];
  if (listQuestion) {
    return listQuestion.add_button_text
  }
  return 'Add';
}

export const getQuestionStatus = (questionList) => {
  const listQuestions = questionList.filter(question => question.display_type === 'list');
  if (listQuestions && listQuestions.length > 0) {
    /* Ideally front end should rely on question_status (valid, or invalid)
     but question_status is not changed based on list input.
     @Nishith has reported bug NAT-1340 to backend team

     Added extra condition for List Add View (when child_questions_completed_flag = false)
     */
    /**
     * In case of multiple lists present in the same section, need to check if any or both the list are valid or not
     * Refer to the defect AM-2095 for this usecase
     */
     return listQuestions.some(question => !!question.question_status_message || !question.child_questions_completed_flag);
  }
  return false;
}

