import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled, { withTheme, ThemeProvider } from 'styled-components';
import get from 'lodash.get';
import AppRouter from '../AppRouter';
import Header from './header';
import Footer from '../Components/Footer/footer';
import { getCurrentQuestionSet } from '../redux/selector/questions';
import PdfSuspenseFallback from '../Components/UIElements/Pdf/PdfSuspenseFallback';

const Toast = lazy(() => import('../cruxComponents/Toast'));
const TooltipModal = lazy(() => import('../cruxComponents/utils/TooltipModal'));
const Loader = lazy(() => import('../cruxComponents/Loader'));

// import Toast from '../cruxComponents/Toast';
// import TooltipModal from '../cruxComponents/utils/TooltipModal';
// import Loader from '../cruxComponents/Loader';

const Body = styled.main`
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	${({ background }) => `
		background: ${background}
	`}
`;

const Layout = ({ 
	theme, 
	loading, 
	currentPageSeqNumber, 
	isSmallScreen, 
	deviceType,
	actionMessage, 
	activeSubsectionId,
	questionsLayout,
	popupDetails,
	redirectUrl,
	isRequestHelpButton,
	uid,
}) => {
	const {
		config: {
			theme: { global, pageOverrides },
			footer,
			layout,
			customHeader,
		},
	} = theme;

	const pageId = window.location.pathname.split('/')[1] || '';
	let pageLayout = pageId ? layout[pageId] : 'auto';
	if (loading && currentPageSeqNumber < 1) {
		pageLayout = 'single';
	}
	if (!layout[pageId]) {
		pageLayout = 'auto';
	}
	if (questionsLayout) {
		pageLayout = questionsLayout;
	}

	// Adds toast message only if action message is of type success
	const hasSuccessActionMessage = actionMessage && actionMessage.type === 'success';
	const {
		globalHeaderTheme: { hideHeader } = {},
		globalFooterTheme: { hideFooter } = {},
	} =  get(pageOverrides, [pageId], {});
	const btnTheme = get(global, 'footerBanner.button', null);

	const isRedirect = pageId === 'signature' && redirectUrl;
	return (
		<ThemeProvider theme={ { ...theme, pageId, pageLayout, isSmallScreen,deviceType, activeSubsectionId } }>
			{hasSuccessActionMessage && <Suspense><Toast type='success' message={ actionMessage.message } /></Suspense> }
			{isRedirect ? <Loader /> : (
				<Suspense fallback={ <><Loader/></> } >
				<Body background={ global.background }>
					{pageId === 'login' ? <AppRouter /> :
						<>
							{!hideHeader && <Header
								name='header'
								layout={ pageLayout }
								background={ global.background }
								isSmallScreen={ isSmallScreen }
								loading={ loading }
								customHeader={ customHeader }
								pageId={ pageId }
							/>}
							<AppRouter />
							{!hideFooter && <Footer
								footer={ { ...footer, background: global.footerBackground, color: global.footerTextColor, btnTheme } }
								theme={ theme }
								pageId={ pageId }
								uid = { uid }
								isRequestHelpButton = { isRequestHelpButton }
							/>}
						</>
					}
					{popupDetails && popupDetails.show && <Suspense fallback={ <PdfSuspenseFallback/> }><TooltipModal /></Suspense>}
				</Body>
				</Suspense>
			)}
		</ThemeProvider>
	);
};

Layout.propTypes = {
	theme: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
	const { otherParams } = getCurrentQuestionSet(state);
	return {
		loading: state.questions.isLoading,
		actionMessage: state.questions.actionMessage,
		currentPageSeqNumber: state.questions.currentPageSeqNumber,
		isSmallScreen: state.meta.isSmallScreen,
		deviceType: state.meta.deviceType,
		activeSubsectionId: state.questions.activeSubsectionId,
		questionsLayout: state.questions.layout,	
		popupDetails: state.questions.popupDetails,
		redirectUrl: state.questions.signatureRedirect,
		isRequestHelpButton: otherParams?.request_help_button,
		uid: state.uid,
	}
};

export default withTheme(connect(mapStateToProps, null)(Layout));
