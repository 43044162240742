import {
	APP_CONFIG, SET_UID,
} from '../types';

const appConfig = (config) => ({
	type: APP_CONFIG,
	payload: config,
})

const setUid = (uid) => ({
	type: SET_UID,
	uid,
});

export { appConfig, setUid }




