import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import createRootReducer from './reducers';
import activityLoggerMiddleware from './middleware/activityLoggerMiddleware';
import adobeLaunchMiddleware from './middleware/adobeLaunchMiddleware';
import { PROD, UAT, UAT2 } from '../constants';

export const history = createBrowserHistory();

const logger = createLogger({
  collapsed: true,
})

export default function configureStore(preloadedState) {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  let middlewares = [
    routerMiddleware(history),
    thunk,
  ]
  if (process.env.REACT_APP_CARRIER_ID === 'nw') {
    middlewares = [
      ...middlewares,
      activityLoggerMiddleware,
    ]
  }
  if (process.env.REACT_APP_CARRIER_ID === 'as') {
    middlewares = [
      ...middlewares,
      adobeLaunchMiddleware,
    ]
  }
  if (![UAT, UAT2, PROD].includes(process.env.REACT_APP_NODE_ENV)) {
    middlewares = [
      ...middlewares,
      logger,
    ]
  }
  return createStore(
    createRootReducer(history),
    preloadedState,
    composeEnhancers(
      applyMiddleware(
        ...middlewares,
      ),
    ),
  );
}
