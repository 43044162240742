/* eslint-disable no-console */
import { DEV, DEV2, LOCAL } from './constants';

export default function log() {
  global.console.log = (()=> {
    const orig = console.log;
    return (...args)=> {
      const _args= args;
      try {
        _args[0] = `[${  new Date().toUTCString()  }] ${  _args[0]}`;
        orig.apply(console, args);
      } catch (err) {
        orig.apply(console, _args);
      }
    };
  })();

  global.console.error = (()=> {
    const orig = console.error;
    return (...args)=>{
      if ([LOCAL, DEV, DEV2].includes(process.env.REACT_APP_NODE_ENV)) {
        orig.apply(console, args);
      }
    }
  })();

  window.onbeforeunload = () => {
    window.scrollTo(0, 0);
  }
};
