import { useRef, useEffect } from 'react'

/* using custom hook for achieving dynamic titles in place of react-helmet */
function useDocumentTitle(docTitle, prevailOnUnmount = false, breadcrumbData = {}) {
  const defaultTitle = useRef(document.title);

  const isEmpty = (obj) => {
    return obj && Object.keys(obj).length === 0;
  }

  const { userAgent } = window.navigator;
  let browserName;

  if(userAgent.match(/chrome|chromium|crios/i)){
    browserName = 'Google Chrome';
  }else if(userAgent.match(/firefox|fxios/i)){
    browserName = 'Mozilla Firefox';
  }  else if(userAgent.match(/safari/i)){
    browserName = 'Safari';
  }else if(userAgent.match(/opr\//i)){
    browserName = 'Opera';
  } else if(userAgent.match(/edg/i)){
    browserName = 'Edge';
  }else if(userAgent.match(/trident/i)){
    browserName = 'MS IE';
  }else{
    browserName='Other';
  }

  useEffect(() => {
    // eslint-disable-next-line no-param-reassign
    const customPageTitles = {
      'plan' : 'Plan Results',
      'partb': 'Proceed To PartB',
    }

    let title = docTitle;

    if(document.URL.includes('plan'))
      title = `${customPageTitles.plan} ${docTitle}`;
    else if(document.URL.includes('partb'))
      title = `${customPageTitles.partb} ${docTitle}`;

    const isActiveBreadcrumb = !isEmpty(breadcrumbData) && breadcrumbData?.active && breadcrumbData?.show
    const documentTitle = isActiveBreadcrumb ? `${breadcrumbData?.title} Amica | Acquire ${browserName}` : `${title} ${browserName}`;
    if(title !== '' || (isActiveBreadcrumb))  {
      document.title = documentTitle;
    }
  }, [docTitle, breadcrumbData?.active]);

  useEffect(() => () => {
    if (!prevailOnUnmount) {
      document.title = defaultTitle.current;
    }
  }, [])
}

export default useDocumentTitle